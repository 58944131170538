import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import useAuth from "../hooks/useAuth";
import { DOMAINCONSTRUCTOR } from "../API/DOMAINS";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const Upload = () => {
  const session = useAuth();
  const navigate = useNavigate();
  const [exceptNumberAlert, setExceptNumberAlert] = useState(false);
  const [date, setDate] = useState("");
  const [round, setRound] = useState("");
  const [file, setFile] = useState(null);
  const [examType, setExamType] = useState("");
  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("date", date);
    formData.append("round", round);
    formData.append("type", examType);
    formData.append("excelFile", file);

    fetch(DOMAINCONSTRUCTOR("upload"), {
      method: "POST",
      body: formData,
    }).then((res) => {
      if (res.status === 201) {
        alert("업로드가 완료되었습니다.");
        navigate("/game");
      } else {
        res.json().then((res) => {
          alert("업로드에 실패하였습니다.\n" + res.error_message);
        });
      }
    });
  };
  const handleSelectDate = (e) => {
    setDate(e.target.value);
  };

  const handleRoundInput = (e) => {
    const re = /^[0-9\b]+$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      setRound(e.target.value);
    } else {
      setExceptNumberAlert(true);
      setTimeout(() => {
        setExceptNumberAlert(false);
      }, 1000);
    }
  };
  const handleFile = (e) => {
    setFile(e.target.files[0]);
  };
  const handleSelectExamType = (e) => {
    setExamType(e.target.value);
  };
  const handleDownload = (type) => {
    window.open(DOMAINCONSTRUCTOR(`game_${type}_file`));
  };

  if (!session.isAuth) return null;
  if (session.type !== 1) navigate("/");
  return (
    <div className="container">
      <div className="row mt-5">
        <h2>결과파일 업로드</h2>
      </div>
      <div className="row mt-5">
        <Form onSubmit={handleSubmit}>
          <Row className="mb-3">
            <Col>
              <Form.Label>시행일자</Form.Label>
              <Form.Control
                type="date"
                name="date"
                value={date}
                onChange={handleSelectDate}
                required
              />
            </Col>
            <Col>
              <Form.Label>차수</Form.Label>
              <Form.Control
                placeholder={
                  exceptNumberAlert ? "숫자만 입력 가능합니다." : null
                }
                type="text"
                name="round"
                value={round}
                onChange={handleRoundInput}
                required
              />
            </Col>
          </Row>
          <Row className="mb-3">
            <Col>
              <Form.Label>대회 종류</Form.Label>
              <Form.Select
                name="type"
                onChange={handleSelectExamType}
                value={examType}
                required
              >
                <option value={""}>선택</option>
                <option value="exam">급수시험</option>
                <option value="game">경기대회</option>
              </Form.Select>
            </Col>
            <Col>
              <Form.Label htmlFor="excelFile">결과 엑셀 파일</Form.Label>
              <Form.Control
                type="file"
                id="excelFile"
                name="excelFile"
                accept=".xlsx"
                onChange={handleFile}
                required
              />
              <Form.Text className="text-muted">
                .xlsx 확장자의 엑셀 파일만 업로드 가능합니다.
              </Form.Text>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col>
              <Button
                variant="outline-primary"
                className="ms-2"
                onClick={() => handleDownload("exam")}
              >
                급수시험 예시 엑셀 다운로드
              </Button>
              <Button
                variant="outline-primary"
                className="ms-2"
                onClick={() => handleDownload("game")}
              >
                경기대회 예시 엑셀 다운로드
              </Button>
              <br />
              <Form.Text className="text-muted">
                ※ 예시 파일과 동일한 형태의 파일만 업로드 가능합니다.
              </Form.Text>
            </Col>
            <Col></Col>
          </Row>
          <Row className="justify-content-end">
            <Col xs="auto">
              <Button
                type="submit"
                variant="primary"
                // disabled={!date || !round || !examType || !file || !file === ""}
              >
                업로드
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    </div>
  );
};

export default Upload;
