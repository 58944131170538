import useAuth from "../hooks/useAuth";
import UserForm from "./UserForm";

const Register = () => {
  const session = useAuth();
  if (!session.isAuth) return null;

  return (
    <div class="container">
      <div class="row mt-5">
        <h2>회원등록</h2>
      </div>
      <div class="row mt-5">
        <UserForm mode={"register"} />
      </div>
    </div>
  );
};

export default Register;
