import { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import styled from "styled-components";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import useAuthentication from "../hooks/useAuth";
import Tab from "react-bootstrap/Tab";
import Container from "react-bootstrap/Container";
import Pagination from "react-bootstrap/Pagination";
import CustomPagination from "../API/pagination";
import Tabs from "react-bootstrap/Tabs";
import { DOMAINCONSTRUCTOR } from "../API/DOMAINS";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import {
  userPageLimitAtom,
  userPageAtom,
  modeAtom,
  examPageAtom,
} from "../atom/atoms";
import { Accordion, InputGroup, Spinner } from "react-bootstrap";
import { getKorDate, getKorFullDate, getKorWithDotw } from "../date/getKorDate";

const ExamList = () => {
  const [pageLimit, setPageLimit] = useRecoilState(userPageLimitAtom);
  const [page, setPage] = useState(null);
  const [total, setTotal] = useState(0);
  const isAuth = useAuthentication();
  const [data, setData] = useState([]);
  const [mode, setMode] = useRecoilState(modeAtom);
  const [searchParams] = useSearchParams();
  const [dateList, setDateList] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);

  const searchText = searchParams.get("searchText");
  const handleTabClick = (mode) => {
    setMode(mode);
    setPage(1);
  };
  const getDate = async () => {
    await fetch(DOMAINCONSTRUCTOR("exam_date"), {
      method: "GET",
    }).then((res) => {
      if (res.status === 200) {
        return res.json().then((res) => {
          if (res.data.length > 0) {
            setDateList(res.data);
            setSelectedDate(getKorDate(res.data[0]?.examDate));
          }
        });
      }
    });
  };
  useEffect(() => {
    getDate();
  }, []);
  useEffect(() => {
    const getMemberData = async () => {
      if (!page || !selectedDate || !pageLimit) return;
      await fetch(
        DOMAINCONSTRUCTOR("exam_list") +
          `/${selectedDate}/${page}/${pageLimit}?searchText=${
            searchText ?? ""
          }`,
        {
          method: "GET",
          credentials: "include",
        }
      ).then((res) => {
        if (res.status === 200) {
          return res.json().then((res) => {
            setTotal(res.total);
            setData(res.data);
          });
        } else {
          alert("회원정보를 가져오는데 실패했습니다.");
        }
      });
    };
    const getScoreData = async () => {
      if (!page || !selectedDate || !pageLimit) return;
      await fetch(`${DOMAINCONSTRUCTOR("exam_score")}/${selectedDate}`).then(
        (res) => {
          if (res.status === 200) {
            return res.json().then((res) => {
              setTotal(res.total);
              setData(res.data);
            });
          } else {
            alert("성적정보를 가져오는데 실패했습니다.");
          }
        }
      );
    };

    mode === "student" && getMemberData();
    mode === "result" && getScoreData();
  }, [selectedDate, page, pageLimit, searchText, mode]);
  if (!isAuth) return null;
  return (
    <StyleContainer>
      <StyleRow>
        <h2>급수시험</h2>
      </StyleRow>
      <StyleRow>
        <Tabs
          id="controlled-tab-example"
          activeKey={mode}
          onSelect={handleTabClick}
          className="mb-3"
        >
          <Tab eventKey="student" title="응시관리">
            <PaneView
              getDate={getDate}
              selectedDate={selectedDate}
              setSelectedDate={setSelectedDate}
              dateList={dateList}
              mode={mode}
              data={data}
              total={total}
              page={page}
              setPage={setPage}
              pageLimit={pageLimit}
              setPageLimit={setPageLimit}
              searchText={searchText}
            />
          </Tab>
          <Tab eventKey="result" title="결과조회">
            <PaneView
              getDate={getDate}
              selectedDate={selectedDate}
              setSelectedDate={setSelectedDate}
              dateList={dateList}
              mode={mode}
              data={data}
              total={total}
              page={page}
              setPage={setPage}
              pageLimit={pageLimit}
              setPageLimit={setPageLimit}
              searchText={searchText}
            />
          </Tab>
        </Tabs>
      </StyleRow>
    </StyleContainer>
  );
};

const PaneView = ({
  getDate,
  selectedDate,
  setSelectedDate,
  dateList,
  mode,
  data,
  total,
  page,
  setPage,
  pageLimit,
  setPageLimit,
  setSearch,
  searchText,
}) => {
  const [selectAll, setSelectAll] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [pageList, setPageList] = useState([]);
  const [savePage, setSavePage] = useRecoilState(examPageAtom);
  const [inputValue, setInputValue] = useState("");
  const [accordionStates, setAccordionStates] = useState(
    Array(pageLimit).fill(false)
  );
  const [displayState, setDisplayState] = useState(
    Array(pageLimit).fill("none")
  );
  const [accordionContent, setAccordionContent] = useState(
    Array(pageLimit).fill(null)
  );
  const [accordionLoading, setAccordionLoading] = useState(
    Array(pageLimit).fill(false)
  );
  useEffect(() => {
    setAccordionStates(Array(pageLimit).fill(false));
    setDisplayState(Array(pageLimit).fill("none"));
    setAccordionContent(Array(pageLimit).fill(null));
    setAccordionLoading(Array(pageLimit).fill(false));
  }, [page, pageLimit, selectedDate, mode]);
  // useEffect(() => {
  //   if (savePage === page) return;
  //   if (savePage === null) return;
  //   setPage(savePage);
  //   setSavePage(null);
  // }, [savePage]);
  useEffect(() => {
    setPage(savePage);
  }, []);
  const navigate = useNavigate();
  useEffect(() => {
    setSelectedItems([]);
    setSelectAll(false);
  }, [mode]);
  const onSubmitHandler = (e) => {
    e.preventDefault();
    setPage(1);
    navigate(`/exam?searchText=${e.target.searchText.value}`);
  };

  useEffect(() => {
    setPageList(CustomPagination(page, setPage, total, pageLimit, setSavePage));
  }, [page, setPage, total, pageLimit, setSavePage]);
  const getCurrentPageData = () => {
    return data.map((item) => item.id);
  };
  const handleSelectAll = () => {
    if (!selectAll) {
      setSelectedItems(getCurrentPageData());
    } else {
      setSelectedItems([]);
    }
    setSelectAll(!selectAll);
  };

  const handleSelectItem = (id) => {
    const selectedIndex = selectedItems.indexOf(id);
    if (selectedIndex === -1) {
      setSelectedItems([...selectedItems, id]);
    } else {
      const updatedSelectedItems = [...selectedItems];
      updatedSelectedItems.splice(selectedIndex, 1);
      setSelectedItems(updatedSelectedItems);
    }
  };
  const handleSelectDate = (e) => {
    setSelectedDate(e.target.value);
  };

  useEffect(() => {
    setInputValue(searchText);
  }, [searchText]);

  const register = async () => {
    const formData = {};
    formData["users"] = selectedItems;
    await fetch(`${DOMAINCONSTRUCTOR("exam_register")}/${selectedDate}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    }).then((res) => {
      if (res.status === 200) {
        alert("응시등록이 완료되었습니다.");
        window.location.reload();
      } else {
        alert("응시등록에 실패했습니다.");
      }
    });
  };
  const handleRegister = () => {
    register();
  };

  const handleDischarge = () => {
    const discharge = async () => {
      const formData = {};
      formData["users"] = selectedItems;
      await fetch(`${DOMAINCONSTRUCTOR("exam_discharge")}/${selectedDate}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      }).then((res) => {
        if (res.status === 200) {
          alert("등록취소가 완료되었습니다.");
          window.location.reload();
        } else {
          alert("등록취소에 실패했습니다.");
        }
      });
    };
    discharge();
  };
  const [dateInsertMode, setDateInsertMode] = useState(false);
  const [insertSelectedDate, setInsertSelectedDate] = useState(null);

  const handleInsertDate = (e) => {
    setSelectedDate(e.target.value);
  };
  const handleDateInsertMode = (mode) => {
    setDateInsertMode(mode);
  };
  const insertData = async () => {
    register(insertSelectedDate);
  };
  const getDetailScoreData = (index, memberId) => {
    fetch(DOMAINCONSTRUCTOR("score") + `/${memberId}/${data[index].no}`)
      .then((res) => res.json())
      .then((res) => {
        if (!res.data) {
          const newAccordionContent = [...accordionContent];
          newAccordionContent[index] = null;
          setAccordionContent(newAccordionContent);
          const newAccordionStates = [...accordionStates];
          newAccordionStates[index] = !newAccordionStates[index];
          setAccordionStates(newAccordionStates);
          const newAccordionLoading = [...accordionLoading];
          newAccordionLoading[index] = false;
          setAccordionLoading(newAccordionLoading);
          changeDisplay(index);
          return;
        }
        const problemInfoRaw = res.data.problemInfo;
        const problemInfoArray = problemInfoRaw.split("@");
        const problemInfo = problemInfoArray.slice(0, -1).map((item) => {
          const problemNumber = item.split("#")[0];
          const problemResult = item.split("#")[1];
          const problem = item.split("#")[2];
          const problemAnswer = item.split("#")[3];
          const problemUserAnswer = item.split("#")[4];
          return {
            problemNumber: problemNumber,
            problemResult: problemResult,
            problem: problem,
            problemAnswer: problemAnswer,
            problemUserAnswer: problemUserAnswer,
          };
        });
        const newAccordionContent = [...accordionContent];
        newAccordionContent[index] = problemInfo;
        setAccordionContent(newAccordionContent);
        const newAccordionStates = [...accordionStates];
        newAccordionStates[index] = !newAccordionStates[index];
        setAccordionStates(newAccordionStates);
        const newAccordionLoading = [...accordionLoading];
        newAccordionLoading[index] = false;
        setAccordionLoading(newAccordionLoading);
        changeDisplay(index);
      });
  };
  const toggleAccordion = (index, memberId) => {
    if (accordionContent[index]) {
      const newAccordionStates = [...accordionStates];
      newAccordionStates[index] = !newAccordionStates[index];
      setAccordionStates(newAccordionStates);
      changeDisplay(index);
      return;
    } else {
      setAccordionLoading(true);
      getDetailScoreData(index, memberId);
    }
  };

  const changeDisplay = (index) => {
    const newDisplayState = [...displayState];
    if (newDisplayState[index] === "") {
      const timeout = setTimeout(() => {
        newDisplayState[index] = "none";
        setDisplayState(newDisplayState);
      }, 300);

      return () => clearTimeout(timeout);
    } else {
      newDisplayState[index] = "";
      setDisplayState(newDisplayState);
    }
  };

  return (
    <>
      <Row className="mb-3">
        <Col>
          {!dateInsertMode ? (
            <Form.Select value={selectedDate} onChange={handleSelectDate}>
              {dateList && dateList.length > 0 ? (
                dateList.map((item, idx) => {
                  return (
                    <option key={idx} value={getKorDate(item.examDate)}>
                      {getKorWithDotw(item.examDate)}
                    </option>
                  );
                })
              ) : (
                <option value={null}>날짜를 생성해주세요.</option>
              )}
            </Form.Select>
          ) : (
            <Form.Control
              type="date"
              value={selectedDate}
              onChange={handleInsertDate}
            />
          )}
        </Col>
        <Col>
          {mode === "student" &&
            (!dateInsertMode ? (
              <Button
                onClick={() => handleDateInsertMode(true)}
                variant="outline-primary"
              >
                날짜 선택
              </Button>
            ) : (
              <Button
                onClick={() => handleDateInsertMode(false)}
                variant="danger"
              >
                취소
              </Button>
            ))}
        </Col>
        <Col>
          <Form.Select
            value={pageLimit}
            onChange={(e) => {
              setPageLimit(Number(e.target.value));
              setPage(1);
            }}
          >
            <option value={15}>15명씩 보기</option>
            <option value={30}>30명씩 보기</option>
            <option value={50}>50명씩 보기</option>
            <option value={100}>100명씩 보기</option>
          </Form.Select>
        </Col>
        {mode === "student" && (
          <Col>
            <Form onSubmit={onSubmitHandler}>
              <InputGroup>
                <Form.Control
                  type="text"
                  placeholder="검색"
                  name="searchText"
                  defaultValue={inputValue}
                />
                <Button type="submit" variant="outline-secondary">
                  검색
                </Button>
              </InputGroup>
            </Form>
          </Col>
        )}
      </Row>
      <Row className="mb-3">
        <Col></Col>
        <Col></Col>
        <Col></Col>
        <Col className="d-flex justify-content-end">
          {mode === "student" && (
            <>
              <div style={{ marginRight: "3px" }}>
                <Button
                  onClick={handleRegister}
                  disabled={selectedItems.length <= 0}
                  variant="success"
                >
                  응시등록
                </Button>
              </div>
              <Button
                onClick={handleDischarge}
                disabled={selectedItems.length <= 0}
                variant="danger"
              >
                등록취소
              </Button>
            </>
          )}
        </Col>
      </Row>
      <Col xs={12} className="table-responsive">
        {mode === "student" && (
          <table className="table table-bordered align-middle table-hover">
            <thead>
              <tr>
                <StyleTh onClick={null}>
                  <Form.Check
                    type="checkbox"
                    id="select-all"
                    checked={getCurrentPageData().every((item) =>
                      selectedItems.includes(item)
                    )}
                    onChange={handleSelectAll}
                  />
                </StyleTh>
                <StyleTh>회원종류</StyleTh>
                <StyleTh>이름/아이디</StyleTh>
                <StyleTh>학교</StyleTh>
                <StyleTh>전화번호</StyleTh>
                <StyleTh>선생님 이름/아이디</StyleTh>
                <StyleTh>상태</StyleTh>
              </tr>
            </thead>
            <tbody>
              {data.length > 0 ? (
                data.map((item, idx) => {
                  return (
                    <tr key={idx}>
                      <StyleTd onClick={null}>
                        <Form.Check
                          type="checkbox"
                          id={`select-${idx}`}
                          checked={selectedItems.includes(item.id)}
                          onChange={() => handleSelectItem(item.id)}
                        />
                      </StyleTd>
                      <StyleTd>
                        {item["type"] === 3
                          ? "학생"
                          : item["type"] === 2
                          ? "선생님"
                          : "최고관리자"}
                      </StyleTd>
                      <StyleTd>
                        <span style={{ fontWeight: "bold" }}>
                          {item["name"]}
                        </span>
                        <br />
                        {item["userid"]}
                      </StyleTd>
                      <StyleTd>{item["schoolName"]}</StyleTd>
                      <StyleTd>{item["phoneNumber"]}</StyleTd>
                      <StyleTd>
                        {item["type"] === 3 && (
                          <>
                            <span style={{ fontWeight: "bold" }}>
                              {item["teacherName"]}
                            </span>
                            <br />
                            {item["teacher"]}
                          </>
                        )}
                      </StyleTd>
                      <StyleTd
                        style={{ background: item["hasRegister"] && "#C4EBD6" }}
                      >
                        {item["hasRegister"] ? "등록됨" : "-"}
                      </StyleTd>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={10}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <span>데이터가 없습니다.</span>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        )}
        {mode === "result" && (
          <table className="table table-bordered align-middle">
            <tbody>
              {data.length > 0 ? (
                data.map((item, idx) => {
                  return (
                    <>
                      <tr style={{ background: "#fafafa", fontWeight: "bold" }}>
                        <StyleTh>이름/아이디</StyleTh>
                        <StyleTh>학교</StyleTh>
                        <StyleTh>선생님 이름/아이디</StyleTh>
                        <StyleTh>모드</StyleTh>
                        <StyleTh>점수</StyleTh>
                        <StyleTh>플레이시간</StyleTh>
                        <StyleTh>문제수</StyleTh>
                        <StyleTh>행수</StyleTh>
                        <StyleTh>범위</StyleTh>
                        <StyleTh>뺄셈빈도</StyleTh>
                        <StyleTh>시간</StyleTh>
                        <StyleTh>상세보기</StyleTh>
                      </tr>
                      <tr className="accordion-header" id={idx} key={idx}>
                        <StyleTd>
                          <span style={{ fontWeight: "bold" }}>
                            {item["name"]}
                          </span>
                          <br />
                          {item["userid"]}
                        </StyleTd>
                        <StyleTd>{item["schoolName"]}</StyleTd>
                        <StyleTd>
                          <span style={{ fontWeight: "bold" }}>
                            {item["teacherName"]}
                          </span>
                          <br />
                          {item["teacher"]}
                        </StyleTd>
                        <StyleTd>{item["kind"]}</StyleTd>
                        <StyleTd>{item["score"]}</StyleTd>
                        <StyleTd>{item["studyTime"]}</StyleTd>
                        <StyleTd>
                          {item["problemNum"] !== -1
                            ? item["problemNum"]
                            : "없음"}
                        </StyleTd>
                        <StyleTd>
                          {item["rowNum"] !== -1 ? item["rowNum"] : "없음"}
                        </StyleTd>
                        <StyleTd>
                          {item["rangeNum"] !== "" ? item["rangeNum"] : "없음"}
                        </StyleTd>
                        <StyleTd>
                          {item["frequency"] !== -1
                            ? item["frequency"]
                            : "없음"}
                        </StyleTd>
                        <StyleTd>
                          {item["insertTime"] &&
                            getKorFullDate(item["insertTime"])}
                        </StyleTd>
                        <StyleTd>
                          {accordionLoading[idx] ? (
                            <Spinner animation="border" variant="primary" />
                          ) : (
                            <Button
                              variant="outline-secondary"
                              onClick={() => toggleAccordion(idx, item["id"])}
                              style={{ width: "40px" }}
                            >
                              {accordionStates[idx] ? "-" : "+"}
                            </Button>
                          )}
                        </StyleTd>
                      </tr>
                      <tr
                        style={{
                          display: displayState[idx],
                          opacity: accordionStates[idx] ? "1" : "0",
                          transition: "opacity 0.3s ease-out",
                        }}
                      >
                        <td colSpan="12">
                          <Accordion
                            activeKey={accordionStates[idx] ? "0" : ""}
                          >
                            <Accordion.Item eventKey="0">
                              <Accordion.Body>
                                {accordionContent[idx] ? (
                                  <table className="table table-bordered align-middle">
                                    <thead>
                                      <tr className="thead-dark">
                                        <StyleTh>번호</StyleTh>
                                        <StyleTh>결과</StyleTh>
                                        <StyleTh>문제</StyleTh>
                                        <StyleTh>정답</StyleTh>
                                        <StyleTh>쓴답</StyleTh>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {accordionContent[idx]?.map(
                                        (accordionItem, accordionIdx) => (
                                          <tr key={accordionIdx}>
                                            <StyleTd>
                                              {accordionItem["problemNumber"]}
                                            </StyleTd>
                                            <StyleTd
                                              style={{
                                                backgroundColor:
                                                  accordionItem[
                                                    "problemResult"
                                                  ] === "O"
                                                    ? "#BEEFFF"
                                                    : "#CD7A7A",
                                              }}
                                            >
                                              {accordionItem["problemResult"]}
                                            </StyleTd>
                                            <StyleTd>
                                              {accordionItem["problem"]}
                                            </StyleTd>
                                            <StyleTd>
                                              {accordionItem["problemAnswer"]}
                                            </StyleTd>
                                            <StyleTd>
                                              {
                                                accordionItem[
                                                  "problemUserAnswer"
                                                ]
                                              }
                                            </StyleTd>
                                          </tr>
                                        )
                                      )}
                                    </tbody>
                                  </table>
                                ) : (
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                  >
                                    <span>
                                      해당 성적의 상세정보가 없습니다.
                                    </span>
                                  </div>
                                )}
                              </Accordion.Body>
                            </Accordion.Item>
                          </Accordion>
                        </td>
                      </tr>
                    </>
                  );
                })
              ) : (
                <tr>
                  <td colSpan="11">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <span>성적 데이터가 없습니다.</span>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        )}
      </Col>
      <Row>
        <Col></Col>
        <Col style={{ display: "flex", justifyContent: "center" }}>
          {data.length > 0 && <Pagination>{pageList}</Pagination>}
        </Col>
        <Col></Col>
      </Row>
    </>
  );
};

export default ExamList;

const StyleTd = styled.td`
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
`;

const StyleTh = styled.th`
  text-align: center;
  white-space: nowrap;
  background-color: transparent !important;
`;

const StyleContainer = styled(Container)`
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

const StyleRow = styled(Row)`
  margin-top: 3rem;
`;
