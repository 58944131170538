import Pagination from "react-bootstrap/Pagination";

const CustomPagination = (
  page,
  setPage,
  total,
  pageLimit,
  setSavePage = null
) => {
  const maxPage = Math.ceil(total / pageLimit);
  const showPage = Math.floor((page - 1) / 5) * 5 + 1;
  let item = [];
  item.push(
    <>
      <Pagination.First
        disabled={page === 1}
        onClick={() => {
          if (showPage !== 1) {
            setSavePage && setSavePage(showPage - 1);
            setPage(showPage - 1);
          } else if (showPage === 1) {
            setSavePage && setSavePage(1);
            setPage(1);
          }
        }}
      />
      <Pagination.Prev
        disabled={page === 1}
        onClick={() => {
          setSavePage && setSavePage(page - 1);
          setPage(page - 1);
        }}
      />
    </>
  );
  for (
    let number = showPage;
    number < (showPage + 5 > maxPage ? maxPage + 1 : showPage + 5);
    number++
  ) {
    item.push(
      <Pagination.Item
        key={number}
        active={number === page}
        onClick={() => {
          setSavePage && setSavePage(number);
          setPage(number);
        }}
      >
        {number}
      </Pagination.Item>
    );
  }
  item.push(
    <>
      <Pagination.Next
        disabled={page === maxPage}
        onClick={() => {
          setSavePage && setSavePage(page + 1);
          setPage(page + 1);
        }}
      />
      <Pagination.Last
        disabled={page === maxPage}
        onClick={() => {
          if (showPage + 5 > maxPage) {
            setSavePage && setSavePage(maxPage);
            setPage(maxPage);
          } else {
            setSavePage && setSavePage(showPage + 5);
            setPage(showPage + 5);
          }
        }}
      />
    </>
  );

  return item;
};

export default CustomPagination;
