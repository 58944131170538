import { Badge, List, message, Modal, Space } from "antd";
import { useCallback, useEffect, useState } from "react";
import { DOMAINCONSTRUCTOR } from "../API/DOMAINS";
import { styled } from "styled-components";

const MessageDetailModal = ({ open, onClose, messageRequestId }) => {
  const [messageApi, contextHolder] = message.useMessage();
  const [result, setResult] = useState(null);

  const fetchEachMessages = useCallback(
    async (requestId) => {
      const res = await fetch(
        `${DOMAINCONSTRUCTOR("message_request_message")}/result/${requestId}`,
        {
          method: "GET",
        }
      );

      if (res.status !== 200) {
        // alert("메시지를 불러오는데 실패했습니다.");
        messageApi.error("메시지를 불러오는데 실패했습니다.");
        return;
      }
      const data = await res.json();
      const recipientInfoTemp = data.recipientInfo.map((info) => {
        return {
          name: info.split("/")[0],
          phone: info.split("/")[1].replace(/-/g, ""),
        };
      });

      const newData = {
        ...data,
        recipientInfo: recipientInfoTemp,
      };

      setResult(newData);
    },
    [messageApi]
  );

  useEffect(() => {
    if (messageRequestId) {
      fetchEachMessages(messageRequestId);
    }
  }, [fetchEachMessages, messageRequestId]);

  return (
    <Modal
      title="메시지 전송내역"
      open={open}
      destroyOnClose
      onCancel={onClose}
      footer={null}
      closeIcon={null}
    >
      {contextHolder}
      {result && (
        <Space
          direction="vertical"
          style={{
            width: "100%",
          }}
        >
          <Badge
            status={
              result.statusName === "success"
                ? "success"
                : result.statusName === "processing"
                ? "processing"
                : "error"
            }
            text={
              result.statusName === "success"
                ? "전송 성공"
                : result.statusName === "processing"
                ? "전송중"
                : "전송 실패"
            }
          />

          {result && (
            <List
              pagination={{
                pageSize: 5,
                showSizeChanger: false,
              }}
              dataSource={result.messages}
              renderItem={(item) => (
                <StyleMessage>
                  {result.kind === "sms" ? (
                    <div>
                      <div>
                        <Badge
                          status={
                            item.status === "READY"
                              ? "default"
                              : item.status === "PROCESSING"
                              ? "processing"
                              : "success"
                          }
                          text={item.statusMessage}
                        />
                        <div>
                          수신자 :{" "}
                          {result.recipientInfo.find(
                            (recipient) => recipient.phone === item.to
                          )
                            ? `${
                                result.recipientInfo.find(
                                  (recipient) => recipient.phone === item.to
                                ).name
                              } (${item.to})`
                            : item.to}
                        </div>
                      </div>
                      <div>전송완료 시각 : {item.completeTime}</div>
                    </div>
                  ) : (
                    <div>
                      <div>
                        알림톡 발송결과 :{" "}
                        <Badge
                          status={
                            item.messageStatusName === "success"
                              ? "success"
                              : item.messageStatusName === "processing"
                              ? "processing"
                              : "error"
                          }
                          text={item.messageStatusDesc}
                        />
                      </div>
                      {item.useSmsFailover &&
                        item.messageStatusName !== "success" && (
                          <div>
                            <div>
                              문자 대체발송 결과 :{" "}
                              <Badge
                                status={
                                  item.failover.messageStatusName === "success"
                                    ? "success"
                                    : item.failover.messageStatusName ===
                                      "processing"
                                    ? "processing"
                                    : "error"
                                }
                                text={item.failover.messageStatusDesc}
                              />
                            </div>
                          </div>
                        )}
                      <div>
                        수신자 :{" "}
                        {result.recipientInfo.find(
                          (recipient) => recipient.phone === item.to
                        )
                          ? `${
                              result.recipientInfo.find(
                                (recipient) => recipient.phone === item.to
                              ).name
                            } (${item.to})`
                          : item.to}
                      </div>
                      <div>
                        전송완료 시각 : {item.completeTime.replace("T", " ")}
                      </div>
                    </div>
                  )}
                </StyleMessage>
              )}
            />
          )}
        </Space>
      )}
    </Modal>
  );
};

export default MessageDetailModal;

const StyleMessage = styled.div`
  width: 100%;
  display: flex;
  padding: 5px;
  border: 1px solid #c0c0c0;
  border-radius: 5px;
  margin-bottom: 5px;
`;
