import { useParams } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import UserForm from "./UserForm";
import { useEffect, useState } from "react";
import { DOMAINCONSTRUCTOR } from "../API/DOMAINS";

const Register = () => {
  const session = useAuth();
  const memberId = useParams().memberId;
  const [validateUser, setValidateUser] = useState(false);

  useEffect(() => {
    const validateStudent = async () => {
      if (session.isAuth) {
        await fetch(
          `${DOMAINCONSTRUCTOR(
            "user_studentValidate"
          )}?studentMemberId=${memberId}`,
          {
            method: "GET",
            credentials: "include",
          }
        )
          .then((res) => {
            if (res.status === 200) {
              return res.json();
            } else {
              return null;
            }
          })
          .then((res) => {
            if (res.data === true) {
              setValidateUser(true);
              return;
            }
            setValidateUser(false);
          });
      }
    };
    validateStudent();
  }, [session, memberId]);

  if (!session.isAuth || validateUser === false) return null;
  return (
    <div class="container">
      <div class="row mt-5">
        <h2>회원정보</h2>
      </div>
      <div class="row mt-5">
        <UserForm key={memberId} mode={"info"} memberId={memberId} />
      </div>
    </div>
  );
};

export default Register;
