import { useRecoilValue } from "recoil";
import { prizeUserAtom } from "../atom/atoms";
import { styled } from "styled-components";
import Button from "react-bootstrap/esm/Button";
import { getKorDate } from "../date/getKorDate";
import { useRef } from "react";

import { useLocation, useNavigate } from "react-router-dom";

const ExamPrize = () => {
  const navigate = useNavigate();
  const containerRef = useRef([]);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const date = params.get("date");
  const print = () => {
    if (!containerRef.current) {
      alert("인쇄할 내용이 없습니다.\n새로고침 후 다시 시도해주세요.");
      return;
    }
    const newWindow = window.open("", "_blank");
    containerRef.current.forEach((item, idx) => {
      item.style.position = "relative";
      if (idx === 0) {
        newWindow.document.body.innerHTML = item.innerHTML;
      } else {
        newWindow.document.body.innerHTML += item.innerHTML;
      }
      if (idx === containerRef.current.length - 1) {
        newWindow.focus();
        newWindow.print();
        setTimeout(() => {
          newWindow.close();
        }, 100);

        navigate(-1);
      }
    });
  };

  const addToRef = (el) => {
    if (el && !containerRef.current.includes(el)) {
      containerRef.current.push(el);
    }
  };
  const prizeUser = useRecoilValue(prizeUserAtom);

  return (
    <StylePrizePreviewContainer>
      <StylePrizePreviewTitleContainer>
        미리보기
      </StylePrizePreviewTitleContainer>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Button onClick={() => print()}>인쇄하기</Button>
      </div>
      {prizeUser &&
        prizeUser.map((item, idx) => {
          return (
            <div
              ref={addToRef}
              style={{
                display: "flex",
                justifyContent: "center",
                background: "gray",
              }}
            >
              <div
                key={idx}
                style={{
                  background: "white",
                  fontFamily: "hy, serif",
                  width: "210mm",
                  minWidth: "210mm",
                  maxWidth: "210mm",
                  height: "297mm",
                  minHeight: "297mm",
                  maxHeight: "297mm",
                  padding: "5cm 3cm 0 3cm",
                  margin: "0",
                  boxSizing: "border-box",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    margin: "0 0.3cm 0.8cm 0.3cm",
                    boxSizing: "border-box",
                    textShadow:
                      "-0.3px 0px black, 0px 0.3px black, 0.3px 0px black, 0px -0.3px black",
                  }}
                >
                  <div
                    style={{
                      fontSize: "16px",
                      flexBasis: 0,
                      flexGrow: 1,
                      textAlign: "left",
                      boxSizing: "border-box",
                    }}
                  >
                    제 {item.license} 호
                  </div>
                  <div
                    style={{
                      fontSize: "16px",
                      flexBasis: 0,
                      flexGrow: 1.5,
                      textAlign: "right",
                      boxSizing: "border-box",
                    }}
                  >
                    민간자격법등록인가 제2018-001151호
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                    width: "100%",
                    alignItems: "center",
                    padding: "0 3cm 0 3cm",
                    boxSizing: "border-box",
                    textShadow:
                      "-1px 0px black, 0px 1px black, 1px 0px black, 0px -1px black",
                  }}
                >
                  <div
                    style={{
                      fontSize: "70px",
                      textAlign: "center",
                      boxSizing: "border-box",
                    }}
                  >
                    자
                  </div>
                  <div
                    style={{
                      fontSize: "70px",
                      textAlign: "center",
                      boxSizing: "border-box",
                    }}
                  >
                    격
                  </div>
                  <div
                    style={{
                      fontSize: "70px",
                      textAlign: "center",
                      boxSizing: "border-box",
                    }}
                  >
                    증
                  </div>
                </div>
                <div style={{ marginTop: "-10px", boxSizing: "border-box" }}>
                  <div
                    style={{
                      textAlign: "center",
                      fontSize: "43px",
                      boxSizing: "border-box",
                    }}
                  >
                    CERTIFICATE
                  </div>
                </div>
                <div
                  style={{
                    marginTop: "2.3cm",
                    boxSizing: "border-box",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      width: "100%",
                      boxSizing: "border-box",
                    }}
                  >
                    <div
                      style={{
                        width: "3.5cm",
                        display: "flex",
                        justifyContent: "space-between",
                        boxSizing: "border-box",
                        marginBottom: "0.2cm",
                      }}
                    >
                      {"등급".split("").map((c) => {
                        return (
                          <div
                            style={{
                              fontSize: "24.5px",
                              boxSizing: "border-box",
                            }}
                          >
                            {c}
                          </div>
                        );
                      })}
                    </div>
                    <div
                      style={{
                        fontSize: "24.5px",
                        textShadow:
                          "-0.3px 0px black, 0px 0.3px black, 0.3px 0px black, 0px -0.3px black",
                        boxSizing: "border-box",
                      }}
                    >
                      &nbsp;:&nbsp;{item.examGrade}
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      width: "100%",
                      boxSizing: "border-box",
                    }}
                  >
                    <div
                      style={{
                        width: "3.5cm",
                        display: "flex",
                        justifyContent: "space-between",
                        boxSizing: "border-box",
                        marginBottom: "0.2cm",
                      }}
                    >
                      {"성명".split("").map((c) => {
                        return (
                          <div
                            style={{
                              fontSize: "24.5px",
                              boxSizing: "border-box",
                            }}
                          >
                            {c}
                          </div>
                        );
                      })}
                    </div>
                    <div
                      style={{
                        fontSize: "24.5px",
                        textShadow:
                          "-0.3px 0px black, 0px 0.3px black, 0.3px 0px black, 0px -0.3px black",
                        boxSizing: "border-box",
                      }}
                    >
                      &nbsp;:&nbsp;{item.name.split("").join(" ")}
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      width: "100%",
                      boxSizing: "border-box",
                    }}
                  >
                    <div
                      style={{
                        width: "3.5cm",
                        display: "flex",
                        justifyContent: "space-between",
                        boxSizing: "border-box",
                      }}
                    >
                      {"생년월일".split("").map((c) => {
                        return (
                          <div
                            style={{
                              fontSize: "24.5px",
                              boxSizing: "border-box",
                            }}
                          >
                            {c}
                          </div>
                        );
                      })}
                    </div>
                    <div
                      style={{
                        fontSize: "24.5px",
                        boxSizing: "border-box",
                        textShadow:
                          "-0.3px 0px black, 0px 0.3px black, 0.3px 0px black, 0px -0.3px black",
                      }}
                    >
                      &nbsp;:&nbsp;
                      {`${getKorDate(item.birthDate).split("-")[0]}년 ${
                        getKorDate(item.birthDate).split("-")[1]
                      }월 ${getKorDate(item.birthDate).split("-")[2]}일`}
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    marginTop: "0.5cm",
                    fontSize: "24.5px",
                    textAlign: "justify",
                    boxSizing: "border-box",
                    lineHeight: "1.5",
                  }}
                >
                  &nbsp;&nbsp;위 사람은 사단법인 한국나노주산·암산교육협회
                  규정에 의거 실시한 능력평가시험에서 위와 같이 합격하였기에 본
                  증서를 드립니다.
                </div>
                <div
                  style={{
                    textAlign: "justify",
                    marginTop: 0,
                    fontSize: "24px",
                    boxSizing: "border-box",
                  }}
                >
                  &nbsp;&nbsp;This certificate has been awarded to above person
                  passing the exam of abacus calculation skills grading
                  conducted by Korea NANO Association of Abacus Mental
                  Arithmetic.
                </div>
                <div
                  style={{
                    marginTop: "1.5cm",
                    fontSize: "25px",
                    textAlign: "center",
                    letterSpacing: "0.1cm",
                    boxSizing: "border-box",
                    textShadow:
                      "-0.5px 0px black, 0px 0.5px black, 0.5px 0px black, 0px -0.5px black",
                  }}
                >
                  {date.split("-")[0]}년 {date.split("-")[1]}월{" "}
                  {date.split("-")[2]}일
                </div>
              </div>
            </div>
          );
        })}
    </StylePrizePreviewContainer>
  );
};

const StylePrizePreviewContainer = styled.div`
  width: 100%;
`;
const StylePrizePreviewTitleContainer = styled.div`
  font-size: 30px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 1cm;
`;

export default ExamPrize;
