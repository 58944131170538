import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useEffect, useState } from "react";
import { DOMAINCONSTRUCTOR } from "../API/DOMAINS";
import { getKorDate } from "../date/getKorDate";
import { useRecoilState } from "recoil";
import { userSession } from "../atom/atoms";

const UserForm = ({ mode, memberId }) => {
  const [session, setSesstion] = useRecoilState(userSession);
  const [passwordValidate, setPasswordValidate] = useState(true);
  const [userData, setUserData] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [type, setType] = useState(3);
  const [password, setPassword] = useState(null);
  const [password2, setPassword2] = useState(null);
  const [userid, setUserid] = useState(null);

  const onUseridHandler = (e) => {
    const regx = new RegExp("[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]");
    if (regx.test(e.currentTarget.value)) {
      return;
    }
    setUserid(e.currentTarget.value);
  };
  const onPasswordHandler = (e) => {
    const regx = new RegExp("[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]");
    if (regx.test(e.currentTarget.value)) {
      return;
    }
    setPassword(e.currentTarget.value);
  };

  const handleEdit = (edit) => {
    if (editMode !== edit && !edit) {
      window.location.reload();
    }
    setEditMode(edit);
  };
  useEffect(() => {
    if (mode !== "info") return;
    fetch(DOMAINCONSTRUCTOR("user") + `/${memberId}`, {
      method: "GET",
    }).then((res) => {
      if (res.status === 200) {
        return res.json().then((res) => {
          setUserData(res.data);
          setType(res.data.type);
        });
      } else if (res.status === 500) {
        return res.json().then((res) => {
          alert("회원정보를 가져오는데 실패했습니다." + res.error_message);
        });
      }
    });
  }, [mode, memberId]);
  const hanldePasswordInput = (e) => {
    const regx = new RegExp("[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]");
    if (regx.test(e.currentTarget.value)) {
      return;
    }
    if (e.currentTarget.value !== e.currentTarget.form.password.value) {
      setPasswordValidate(false);
    } else {
      setPasswordValidate(true);
    }
    setPassword2(e.currentTarget.value);
  };
  const logout = async () => {
    await fetch(DOMAINCONSTRUCTOR("logout"), {
      method: "GET",
    })
      .then((res) => {
        if (res.status === 200) {
          return res.json().then((res) => {
            if (res.result === "success") {
              setSesstion({});
            }
          });
        } else {
          return res.text().then((res) => {
            throw new Error("로그아웃 과정에서 오류가 발생했습니다." + res);
          });
        }
      })
      .catch((err) => {
        alert(err.message);
      });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    let password = null;
    if (mode === "register") {
      if (!passwordValidate) {
        alert("비밀번호가 일치하지 않습니다.");
        return;
      }
      password = e.target.password.value;
    } else {
      if (e.target.password.value !== "" && e.target.password_2.value !== "") {
        if (!passwordValidate) {
          alert("비밀번호가 일치하지 않습니다.");
          return;
        }
        password = e.target.password.value;
      }
    }
    let phoneNumber = `${e.target.phoneNumber1.value}-${e.target.phoneNumber2.value}-${e.target.phoneNumber3.value}`;
    if (
      (e.target.phoneNumber1.value === "010" &&
        e.target.phoneNumber2.value === "" &&
        e.target.phoneNumber3.value === "") ||
      (e.target.phoneNumber1.value === "" &&
        e.target.phoneNumber2.value === "" &&
        e.target.phoneNumber3.value === "")
    ) {
      phoneNumber = "";
    }
    const formData = {};
    if (mode === "info") formData.id = e.target.id.value;
    formData.type = e.target.type.value;
    formData.name = e.target.name.value;
    formData.phoneNumber = phoneNumber;
    formData.userid = e.target.userid.value;
    if (mode === "info") formData.startDate = e.target.startDate.value;
    if (mode === "register") formData.startDate = getKorDate();
    formData.schoolName = e.target.schoolName.value;
    if (mode === "register") formData.teacherMemberId = session.memberId;
    formData.passwd = password;
    await fetch(DOMAINCONSTRUCTOR("user"), {
      method: mode === "register" ? "POST" : "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    })
      .then((res) => {
        if (res.status === 201) {
          if (mode === "register") {
            alert("회원등록이 완료되었습니다.");
            window.location.reload();
          }
          if (mode === "info") {
            if (Number(memberId) === session.memberId) {
              alert("회원정보가 수정되었습니다.\n다시 로그인해주세요");
              logout();
            } else {
              alert("회원정보가 수정되었습니다.");
              window.location.reload();
            }
          }
        }
      })
      .catch((err) => {
        alert(err.message);
      });
  };
  if (!session.isAuth) return null;
  if (mode !== "register" && !userData) return null;
  return (
    <div class="col-12" key={memberId}>
      <Form onSubmit={handleSubmit} id="registerForm">
        <Form.Control type="hidden" name="id" defaultValue={userData?.id} />
        <fieldset>
          <div class="mb-3">
            <Row>
              <Col xs="auto">
                <Form.Group>
                  <Form.Label htmlFor="type-select">구분</Form.Label>
                  {session.type === 1 ? (
                    <Form.Select
                      key={type}
                      id="type-select"
                      name="type"
                      disabled={mode === "info" && !editMode}
                      defaultValue={type}
                      onChange={(e) => setType(e.target.value)}
                    >
                      <option value="3">학생</option>
                      <option value="2">선생님</option>
                      <option value="1">최고관리자</option>
                    </Form.Select>
                  ) : (
                    <Form.Select
                      key={type}
                      id="type-select"
                      name="type"
                      disabled={mode === "info" && !editMode}
                      defaultValue={type}
                      onChange={(e) => setType(e.target.value)}
                    >
                      <option value="3">학생</option>
                      <option disabled value="2">
                        선생님
                      </option>
                    </Form.Select>
                  )}
                </Form.Group>
              </Col>
            </Row>
          </div>
          <div class="mb-3">
            <Row>
              <Col xs="auto">
                <Form.Label htmlFor="name">이름</Form.Label>
                <Form.Control
                  type="text"
                  id="name"
                  name="name"
                  placeholder="이름"
                  disabled={mode === "info" && !editMode}
                  defaultValue={userData?.name}
                  required
                />
                {mode === "register" ||
                  (mode === "info" && editMode && (
                    <Form.Text className="text-muted">
                      한글 최대 25자, 영문 최대 50자
                    </Form.Text>
                  ))}
              </Col>
            </Row>
          </div>
          <div className="mb-3">
            <Row>
              <Col xs="auto">
                <Form.Label htmlFor="schoolName">학교 이름</Form.Label>
                <Form.Control
                  type="text"
                  id="schoolName"
                  name="schoolName"
                  defaultValue={userData?.schoolName}
                  disabled={mode === "info" && !editMode}
                  placeholder="학교 이름"
                />
                {mode === "register" ||
                  (mode === "info" && editMode && (
                    <Form.Text className="text-muted">
                      한글 최대 15자, 영문 최대 30자
                    </Form.Text>
                  ))}
              </Col>
            </Row>
          </div>
          {mode === "info" && (
            <>
              <div className="mb-3">
                <Row>
                  <Col xs="auto">
                    <Form.Label htmlFor="startDate">입소일자</Form.Label>
                    <Form.Control
                      type="date"
                      id="startDate"
                      name="startDate"
                      defaultValue={
                        userData?.startDate && getKorDate(userData?.startDate)
                      }
                      disabled={mode === "info" && !editMode}
                    />
                  </Col>
                </Row>
              </div>
              <div className="mb-3">
                <Row>
                  <Col xs="auto">
                    <Form.Label htmlFor="endDate">퇴소일자</Form.Label>
                    <Form.Control
                      type="date"
                      id="endDate"
                      name="endDate"
                      defaultValue={
                        userData?.endDate && getKorDate(userData?.endDate)
                      }
                      disabled
                    />
                  </Col>
                </Row>
              </div>
            </>
          )}
          <div className="mb-3">
            <Form.Label htmlFor="phoneNumber1">전화번호</Form.Label>
            <Row>
              <Col xs="auto">
                <InputGroup>
                  <Form.Control
                    type="text"
                    id="phoneNumber1"
                    name="phoneNumber1"
                    defaultValue={
                      userData?.phoneNumber?.split("-")[0]
                        ? userData?.phoneNumber?.split("-")[0]
                        : "010"
                    }
                    maxLength={3}
                    disabled={mode === "info" && !editMode}
                    aria-describedby="basic-addon1"
                  />
                  <InputGroup.Text id="basic-addon1">-</InputGroup.Text>
                  <Form.Control
                    type="text"
                    id="phoneNumber2"
                    name="phoneNumber2"
                    defaultValue={userData?.phoneNumber?.split("-")[1]}
                    disabled={mode === "info" && !editMode}
                    maxLength={4}
                    aria-describedby="basic-addon1"
                  />
                  <InputGroup.Text id="basic-addon1">-</InputGroup.Text>
                  <Form.Control
                    type="text"
                    id="phoneNumber3"
                    name="phoneNumber3"
                    defaultValue={userData?.phoneNumber?.split("-")[2]}
                    disabled={mode === "info" && !editMode}
                    maxLength={4}
                    aria-describedby="basic-addon1"
                  />
                </InputGroup>
              </Col>
            </Row>
          </div>
          <Row>
            <Col xs="auto">
              <div className="mb-3">
                <Form.Label htmlFor="userid">아이디</Form.Label>
                <Form.Control
                  type="text"
                  id="userid"
                  name="userid"
                  disabled={mode === "info" && !editMode}
                  placeholder="아이디"
                  onChange={onUseridHandler}
                  defaultValue={userData?.userid}
                  value={userid}
                  required
                />
                {mode === "register" ||
                  (mode === "info" && editMode && (
                    <Form.Text className="text-muted">최대 20자</Form.Text>
                  ))}
              </div>
            </Col>
          </Row>
          <div
            className="mb-3"
            style={{
              visibility:
                mode === "register" || (mode === "info" && editMode)
                  ? "visible"
                  : "hidden",
            }}
          >
            <Row>
              <Col xs="auto">
                <div className="mb-1">
                  <Form.Label htmlFor="password">
                    비밀번호
                    {mode === "info" && editMode && (
                      <span style={{ color: "red" }}>
                        {" "}
                        비밀번호는 변경시에만 입력해주세요.
                      </span>
                    )}
                  </Form.Label>
                  <Form.Control
                    type="password"
                    id="password"
                    name="password"
                    placeholder="비밀번호"
                    required={mode === "register"}
                    onChange={onPasswordHandler}
                    maxLength={50}
                    value={password}
                  />
                </div>
                <Form.Control
                  type="password"
                  id="password_2"
                  name="password_2"
                  onChange={hanldePasswordInput}
                  placeholder="비밀번호 확인"
                  required={mode === "register"}
                  maxLength={50}
                  value={password2}
                />

                <Form.Text
                  className="text-muted"
                  style={{
                    visibility: passwordValidate && "hidden",
                  }}
                >
                  <span style={{ color: "red" }}>
                    비밀번호가 일치하지 않습니다.
                  </span>
                </Form.Text>
              </Col>
            </Row>
          </div>
          <div className="mb-3 d-flex justify-content-between">
            {mode === "info" ? (
              !editMode ? (
                <Button variant="primary" onClick={() => handleEdit(true)}>
                  정보변경
                </Button>
              ) : (
                <Button variant="danger" onClick={() => handleEdit(false)}>
                  취소하기
                </Button>
              )
            ) : (
              <div></div>
            )}

            {(mode !== "info" || (mode === "info" && editMode)) && (
              <Button className="ml-3" variant="primary" type="submit">
                {editMode ? "수정하기" : "제출"}
              </Button>
            )}
          </div>
        </fieldset>
      </Form>
    </div>
  );
};

export default UserForm;
