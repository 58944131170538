import {
  Button,
  Divider,
  Table,
  Tag,
  Segmented,
  Space,
  Typography,
  Alert,
  List,
} from "antd";
import { useCallback, useEffect, useState } from "react";
import useAuth from "../hooks/useAuth";
import { DOMAINCONSTRUCTOR } from "../API/DOMAINS";
import MessageModal from "./MessageModal";
import { getDotwFullDate } from "../date/getKorDate";
import MessageDetailModal from "./MessageDetailModal";

const { Text } = Typography;

const Message = () => {
  const session = useAuth();
  const [authroizedUser, setAuthroizedUser] = useState([]);
  const [requestMessages, setRequestMessages] = useState([]);
  const [modal, setModal] = useState(false);
  const [detailModal, setDetailModal] = useState(false);
  const [selectedMessage, setSelectedMessage] = useState(null);
  const fetchAuthroizedUser = useCallback(async () => {
    if (session.type !== 1) return;
    const res = await fetch(DOMAINCONSTRUCTOR("message_candidate"), {
      method: "GET",
    });
    if (res.status !== 200) {
      // alert("메시지 이용 권한을 불러오는데 실패했습니다.");
      return;
    }
    const data = await res.json();
    setAuthroizedUser(data.data);
  }, [session.type]);

  const fetchRequestMessages = useCallback(async () => {
    const res = await fetch(DOMAINCONSTRUCTOR("message_request_message"), {
      method: "GET",
    });
    if (res.status !== 200) {
      // alert("메시지를 불러오는데 실패했습니다.");
      return;
    }
    const data = await res.json();
    console.log(data.data);
    setRequestMessages(data.data);
  }, []);

  const userAuthrorize = async (memberId, value) => {
    if (session.type !== 1) return;
    const formData = new FormData();
    formData.append("requestMemberId", memberId);
    formData.append("grade", value);

    const res = await fetch(DOMAINCONSTRUCTOR("message_authorized"), {
      method: "POST",
      body: formData,
      credentials: "include",
    });
    if (res.status !== 200) {
      alert("메시지 이용 권한을 부여하는데 실패했습니다.");
      return;
    } else {
      alert("메시지 이용 권한을 부여했습니다.");
    }
    fetchAuthroizedUser();
  };
  useEffect(() => {
    fetchAuthroizedUser();
    fetchRequestMessages();
  }, [fetchAuthroizedUser, fetchRequestMessages]);
  const test = [
    {
      requestId: "RBAA-1712316518682-8512-57738948-JkarQePM",
      requestTime: "2024-04-05T20:28:38.682",
      statusCode: "202",
      statusName: "processing",
      messages: [
        {
          messageId: "42599276-ebea-463c-98f8-afe39c7be83e",
          countryCode: "82",
          to: "01097875645",
          content:
            "안녕하세요 나노주산암산 주산암산 강사 안은선입니다.\n이학생 학생의 학습 습관과 실력 향상에 도움이 되고자, 암산 플래시 숙제가 진행되지 않으면 미해결 안내를 발송해드립니다.\n\n번거로우시더라도 학생의 학습 실력 향상을 위해 꾸준한 지도를 부탁드립니다.\n\n이학생 학생의 암산 플래시 아이디와 비밀번호 안내드립니다.\n\n아이디 : leestudent\n비밀번호 : 생일 4자리 (1월 1일생의 경우 0101)\n\n이학생 학생이 매일 꾸준히 사용할 수 있는 핸드폰에 암산 플래시 앱을 다운로드 받아주시길 바랍니다.\n\n▶ 구글플레이스토어 또는 앱스토어에서\n'주산암산 슬기로운 암산스쿨' 검색 후 다운로드 \n\n감사합니다.",
          requestStatusCode: "A000",
          requestStatusName: "success",
          requestStatusDesc: "성공",
          useSmsFailover: true,
        },
        {
          messageId: "42599276-ebea-463c-98f8-afe39c7be83e",
          countryCode: "82",
          to: "01097875645",
          content:
            "안녕하세요 나노주산암산 주산암산 강사 안은선입니다.\n이학생 학생의 학습 습관과 실력 향상에 도움이 되고자, 암산 플래시 숙제가 진행되지 않으면 미해결 안내를 발송해드립니다.\n\n번거로우시더라도 학생의 학습 실력 향상을 위해 꾸준한 지도를 부탁드립니다.\n\n이학생 학생의 암산 플래시 아이디와 비밀번호 안내드립니다.\n\n아이디 : leestudent\n비밀번호 : 생일 4자리 (1월 1일생의 경우 0101)\n\n이학생 학생이 매일 꾸준히 사용할 수 있는 핸드폰에 암산 플래시 앱을 다운로드 받아주시길 바랍니다.\n\n▶ 구글플레이스토어 또는 앱스토어에서\n'주산암산 슬기로운 암산스쿨' 검색 후 다운로드 \n\n감사합니다.",
          requestStatusCode: "A000",
          requestStatusName: "success",
          requestStatusDesc: "성공",
          useSmsFailover: true,
        },

        {
          messageId: "42599276-ebea-463c-98f8-afe39c7be83e",
          countryCode: "82",
          to: "01097875645",
          content:
            "안녕하세요 나노주산암산 주산암산 강사 안은선입니다.\n이학생 학생의 학습 습관과 실력 향상에 도움이 되고자, 암산 플래시 숙제가 진행되지 않으면 미해결 안내를 발송해드립니다.\n\n번거로우시더라도 학생의 학습 실력 향상을 위해 꾸준한 지도를 부탁드립니다.\n\n이학생 학생의 암산 플래시 아이디와 비밀번호 안내드립니다.\n\n아이디 : leestudent\n비밀번호 : 생일 4자리 (1월 1일생의 경우 0101)\n\n이학생 학생이 매일 꾸준히 사용할 수 있는 핸드폰에 암산 플래시 앱을 다운로드 받아주시길 바랍니다.\n\n▶ 구글플레이스토어 또는 앱스토어에서\n'주산암산 슬기로운 암산스쿨' 검색 후 다운로드 \n\n감사합니다.",
          requestStatusCode: "A000",
          requestStatusName: "fail",
          requestStatusDesc: "성공",
          useSmsFailover: true,
        },
      ],
    },
    {
      requestId: "RBAA-1712755618896-5278-57738948-JkarQePM",
      requestTime: "2024-04-10T22:26:58.896",
      statusCode: "202",
      statusName: "processing",
      messages: [
        {
          messageId: "c9397f16-1855-42e5-981c-0047dc5ed9c8",
          countryCode: "82",
          to: "01097875645",
          content:
            "안녕하세요 나노주산암산 주산암산 강사 안은선입니다.\n이학생 학생은 현재 숙제(암산 플래시) 미완료 상태입니다.\n이학생 학생의 학습 습관과 실력 향상을 위해 꾸준히 숙제할 수 있도록 지도 부탁드립니다.",
          requestStatusCode: "A000",
          requestStatusName: "success",
          requestStatusDesc: "성공",
          useSmsFailover: true,
        },
      ],
    },
  ];

  if (!session.isAuth) return null;
  return (
    <div class="container">
      <MessageModal open={modal} onClose={() => setModal(false)} />
      <MessageDetailModal
        open={detailModal}
        onClose={() => setDetailModal(false)}
        messageRequestId={selectedMessage}
      />
      <div class="row mt-5">
        <h2>메시지</h2>
      </div>
      <Space
        direction="vertical"
        style={{
          width: "100%",
        }}
      >
        <Button
          variant="success"
          onClick={() => {
            setModal(true);
          }}
        >
          메시지 전송
        </Button>
        <Divider orientation="left">전송 내역</Divider>
        <Alert
          message="안내"
          description="세부 전송 내역은 카카오톡 최대 30일(SMS는 최대 90일까지) 조회 가능합니다."
          type="info"
          showIcon
        />
        <List
          pagination={{
            pageSize: 10,
          }}
          bordered
          dataSource={requestMessages}
          renderItem={(item) => (
            <List.Item
              actions={[
                <Button
                  onClick={() => {
                    setSelectedMessage(item.id);
                    setDetailModal(true);
                  }}
                >
                  자세히
                </Button>,
              ]}
            >
              <List.Item.Meta
                title={`${item.kind === "sms" ? "[문자]" : "[알림톡]"} ${
                  item.templateTitle ?? "직접 작성한 메시지"
                }`}
                description={getDotwFullDate(item.requestTime)}
              />
            </List.Item>
          )}
        />
        {session.type === 1 && (
          <>
            <Divider orientation="left">메시지 이용 권한 관리</Divider>
            <Text type="warning">
              최고 관리자 외 선생님 권한의 유저에게는 이용권한을 부여해야합니다.
            </Text>
            <Table pagination={false} dataSource={authroizedUser}>
              <Table.Column title="아이디" dataIndex="userid" key="userid" />
              <Table.Column title="이름" dataIndex="name" key="name" />
              <Table.Column
                title="권한"
                dataIndex="messageAuthorizedGrade"
                key="messageAuthorizedGrade"
                render={(messageAuthorizedGrade) => (
                  <Tag
                    color={
                      messageAuthorizedGrade === 2
                        ? "green"
                        : messageAuthorizedGrade === 1
                        ? "blue"
                        : messageAuthorizedGrade === 0
                        ? "red"
                        : "gray"
                    }
                  >
                    {messageAuthorizedGrade === 2
                      ? "전체 이용 가능"
                      : messageAuthorizedGrade === 1
                      ? "기본 템플릿 이용가능"
                      : messageAuthorizedGrade === 0
                      ? "전체 이용 불가능"
                      : "알수없음"}
                  </Tag>
                )}
              />
              <Table.Column
                title="권한 부여"
                key="action"
                render={(_, record) => (
                  <Segmented
                    onChange={(e) => {
                      console.log(e);
                      const value =
                        e === "전체 허용"
                          ? 2
                          : e === "기본 템플릿만 허용"
                          ? 1
                          : e === "비허용"
                          ? 0
                          : null;

                      if (value === null) {
                        alert(
                          "권한설정에 에러가 발생했습니다.\n다시 시도해주세요."
                        );
                        return;
                      }
                      userAuthrorize(record.id, value);
                    }}
                    options={["전체 허용", "기본 템플릿만 허용", "비허용"]}
                    value={
                      record.messageAuthorizedGrade === 0
                        ? "비허용"
                        : record.messageAuthorizedGrade === 1
                        ? "기본 템플릿만 허용"
                        : record.messageAuthorizedGrade === 2
                        ? "전체 허용"
                        : null
                    }
                  />
                )}
              />
            </Table>
          </>
        )}
      </Space>
    </div>
  );
};

export default Message;
