const DOMAIN = "https://jusanjjang.com";

const LOGIN = "/api/auth/login";
const LOGOUT = "/api/auth/logout";
const SESSION = "/api/auth/session";
const EXCEL_UPLOAD = "/api/game/excel";
const UPLOAD = "/api/game/upload";

const USER = "/api/user";
const USER_LIST = "/api/user/list";
const USER_LIST_RESIGN = "/api/user/list/resign";
const USER_LIST_REMOVE = "/api/user/list/remove";
const USER_STUDENTVALIDATE = "/api/user/studentValidate";

const SCORE = "/api/score";

const GAME = "/api/game/list";
const GAME_SCORE = "/api/game/list/score";
const GAME_VISIBLE = "/api/game/list/visible";
const GAME_DOWNLOAD_EXCEL_FILE = "/api/game/list/downloadExcel";
const GAME_EXAM_FILE = "/api/game/getExamFile";
const GAME_GAME_FILE = "/api/game/getGameFile";
const GAME_TITLE = "/api/game/title";

const EXAM = "/api/exam";
const EXAM_LIST = "/api/exam/list";
const EXAM_DATE = "/api/exam/date";
const EXAM_SCORE = "/api/exam/score";
const EXAM_REGISTER = "/api/exam/register";
const EXAM_DISCHARGE = "/api/exam/discharge";

const TEST_LIST = "/api/user/testlist";
const MESSAGE = "/api/message";
const MESSAGE_SEND = "/api/message/send";
const MESSAGE_TEMPLATES = "/api/message/templates";
const MESSAGE_LIST = "/api/user/messageList";
const MESSAGE_AUTHORIZED = "/api/user/messageAuthorizedUser";
const MESSAGE_CANDIDATE = "/api/user/messageCandidateUser";
const MESSAGE_REQUEST_MESSAGE = "/api/message/requestMessage";

const PATHS = {
  login: LOGIN,
  logout: LOGOUT,
  session: SESSION,
  excel_upload: EXCEL_UPLOAD,
  upload: UPLOAD,
  user: USER,
  user_list: USER_LIST,
  user_list_resign: USER_LIST_RESIGN,
  user_list_remove: USER_LIST_REMOVE,
  user_studentValidate: USER_STUDENTVALIDATE,
  score: SCORE,
  game: GAME,
  game_score: GAME_SCORE,
  game_visible: GAME_VISIBLE,
  game_exam_file: GAME_EXAM_FILE,
  game_game_file: GAME_GAME_FILE,
  game_download_excel_file: GAME_DOWNLOAD_EXCEL_FILE,
  game_title: GAME_TITLE,
  exam: EXAM,
  exam_list: EXAM_LIST,
  exam_date: EXAM_DATE,
  exam_score: EXAM_SCORE,
  exam_register: EXAM_REGISTER,
  exam_discharge: EXAM_DISCHARGE,
  test_list: TEST_LIST,
  message: MESSAGE,
  message_send: MESSAGE_SEND,
  message_templates: MESSAGE_TEMPLATES,
  message_list: MESSAGE_LIST,
  message_authorized: MESSAGE_AUTHORIZED,
  message_candidate: MESSAGE_CANDIDATE,
  message_request_message: MESSAGE_REQUEST_MESSAGE,
};
export const DOMAINCONSTRUCTOR = (name) => {
  return DOMAIN + PATHS[name];
};
