import {
  DeleteOutlined,
  DownOutlined,
  ExclamationCircleOutlined,
  LeftOutlined,
  RightOutlined,
} from "@ant-design/icons";
import {
  Alert,
  Button,
  Card,
  List,
  Modal,
  Select,
  Space,
  Tooltip,
  Typography,
  message,
} from "antd";
import Badge from "react-bootstrap/Badge";

import TextArea from "antd/es/input/TextArea";

import { useCallback, useEffect, useRef, useState } from "react";
import { DOMAINCONSTRUCTOR } from "../API/DOMAINS";

import useAuth from "../hooks/useAuth";

const MessageModal = ({ open, onClose }) => {
  const [messageApi, contextHolder] = message.useMessage();
  const [templateModal, setTemplateModal] = useState(false);
  const [templateSaveModal, setTemplateSaveModal] = useState(false);
  const teacherTarget = useRef(null);
  const studentTarget = useRef(null);
  const [alert, setAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [students, setStudents] = useState([]);
  const [selectedStudents, setSelectedStudents] = useState([]);
  const [contents, setContents] = useState("");
  const messageRef = useRef(null);
  const session = useAuth();
  const [templateTitle, setTemplateTitle] = useState("");
  const [studentIndex, setStudentIndex] = useState(0);
  const [messageTemplates, setMessageTemplates] = useState([]);
  const gs = [12, 11, 10, 9, 8, 7, 6, 5, 4, 3, 2, 1];
  const [isGsMode, setIsGsMode] = useState(false);
  const [selectedGrade, setSelectedGrade] = useState(null);
  const [contentAreDisabled, setContentAreDisabled] = useState(true);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const navigateStudent = (direction) => {
    if (direction === -1) {
      if (studentIndex === 0) return;
      setStudentIndex(studentIndex - 1);
    } else {
      if (studentIndex === selectedStudents.length - 1) return;
      setStudentIndex(studentIndex + 1);
    }
  };

  useEffect(() => {
    if (!open) return;
    fetch(DOMAINCONSTRUCTOR("message_list"), {
      method: "GET",
      credentials: "include",
    }).then((res) => {
      if (res.status === 200) {
        return res.json().then((res) => {
          setStudents(res.data);
        });
      } else {
        setAlertMessage("회원정보를 가져오는데 실패했습니다.");
        setAlert(true);
        setTimeout(() => {
          setAlert(false);
        }, 3000);
      }
    });
  }, [open]);
  const fetchMessageTemplats = useCallback(async () => {
    if (selectedStudents.length === 0) return;

    return await fetch(DOMAINCONSTRUCTOR("message_templates"), {
      method: "GET",
      credentials: "include",
    }).then((res) => {
      if (res.status === 200) {
        return res.json().then((res) => {
          const { data } = res;
          setMessageTemplates(data);
          return data;
        });
      } else {
        setAlertMessage("템플릿을 가져오는데 실패했습니다.");
        setAlert(true);
        setTimeout(() => {
          setAlert(false);
        }, 3000);
        return [];
      }
    });
  }, [selectedStudents.length]);
  useEffect(() => {
    fetchMessageTemplats();
  }, [fetchMessageTemplats]);

  const transformVariable = (text, studentIndex) => {
    const transformTeacher = text.replaceAll("{선생님}", session.username);
    const transformSchoolName = transformTeacher.replaceAll(
      "{학원이름}",
      session.schoolName
    );
    const transformedTexts = transformSchoolName.replaceAll(
      "{학생}",
      students.find((std) => std.id === selectedStudents[studentIndex]).name
    );
    const transformedTextWithStudentId = transformedTexts.replaceAll(
      "{아이디}",
      students.find((std) => std.id === selectedStudents[studentIndex]).userid
    );
    const transformedTextWithGrade = transformedTextWithStudentId.replaceAll(
      "{급수}",
      selectedGrade
    );
    return transformedTextWithGrade;
  };

  const handleChangeTemplate = (value, selectedOptions) => {
    setSelectedTemplate(value);
    const selectedTemplate = messageTemplates.find(
      (template) => template.id === value
    );
    setIsGsMode(false);
    if (value === "-1") {
      setContents("");
      setContentAreDisabled(false);
      return;
    } else {
      setContentAreDisabled(true);
    }
    if (
      selectedTemplate.custom === 0 &&
      selectedOptions.label.includes("승급")
    ) {
      setContents("");
      setIsGsMode(true);
      return;
    }
    setIsGsMode(false);
    setSelectedGrade(null);

    // if (selectedTemplate.custom === 0) {
    //   setContentAreDisabled(true);
    // } else {
    //   setContentAreDisabled(false);
    // }
    setContents(selectedTemplate.templateContent);
  };
  const saveTemplate = async () => {
    if (!templateTitle || !contents) return;
    const messageTemplates = await fetchMessageTemplats();
    if (messageTemplates.filter((item) => item.custom === 1).length >= 10) {
      messageApi.error("템플릿은 최대 10개까지 저장할 수 있습니다.");
      return;
    }
    const formData = {};
    formData["title"] = templateTitle;
    formData["contents"] = contents;
    formData["custom"] = true;
    await fetch(DOMAINCONSTRUCTOR("message_templates"), {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    }).then((res) => {
      if (res.status === 201) {
        return res.json().then((res) => {
          messageApi.success("템플릿이 저장되었습니다.");
          fetchMessageTemplats();
          setTemplateSaveModal(false);
        });
      } else {
        messageApi.error("템플릿 저장에 실패했습니다.");
      }
    });
  };
  const addingValue = useCallback(
    (text) => {
      if (messageRef.current.resizableTextArea.textArea === null) return;
      const textArea = messageRef.current.resizableTextArea.textArea;
      const start = textArea.selectionStart;
      const end = textArea.selectionEnd;
      const newText =
        textArea.value.substring(0, start) +
        text +
        textArea.value.substring(end);

      setContents(newText); // 새로운 텍스트로 상태 업데이트

      // 커서 위치 업데이트
      setTimeout(() => {
        textArea.selectionStart = textArea.selectionEnd = start + text.length;
      }, 0);
      textArea.focus();
    },
    [messageRef]
  );

  const suffix = (
    <>
      <span>{selectedStudents.length} / 100</span>
      <DownOutlined />
    </>
  );

  if (!session.isAuth || (!session.messageAuth && !session.type === 1))
    return null;
  return (
    <Modal
      title="메시지 전송"
      open={open}
      destroyOnClose
      maskClosable={false}
      onCancel={() => {
        Modal.confirm({
          title: "정말 종료하시겠습니까?",
          content: "작성중인 내용은 저장되지 않습니다.",
          okText: "종료",
          okType: "danger",
          cancelText: "취소",
          onOk() {
            setContents("");
            setSelectedStudents([]);
            setSelectedTemplate(null);
            setIsGsMode(false);
            setSelectedGrade(null);
            setContentAreDisabled(true);
            onClose();
          },
          onCancel() {},
        });
      }}
      footer={[
        <Space
          style={{
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <Space>
            {(session.messageAuth === 2 || session.type === 1) && (
              <>
                <Tooltip
                  title={
                    contents &&
                    contents.length > 0 &&
                    messageTemplates.filter((item) => item.custom === 1)
                      .length >= 10
                      ? "최대 10개까지 저장할 수 있습니다!"
                      : ""
                  }
                >
                  <Button
                    disabled={
                      !contents ||
                      !contents.length === 0 ||
                      messageTemplates.filter((item) => item.custom === 1)
                        .length >= 10 ||
                      contentAreDisabled
                    }
                    onClick={() => {
                      setTemplateSaveModal(true);
                    }}
                    type="primary"
                  >
                    템플릿 저장
                  </Button>
                </Tooltip>

                <Button
                  onClick={() => {
                    setTemplateModal(true);
                  }}
                  type="primary"
                >
                  템플릿 관리
                </Button>
              </>
            )}
          </Space>
          <Space>
            <Button
              disabled={selectedStudents?.length === 0 || !contents}
              type="primary"
              onClick={async () => {
                const template = messageTemplates.find(
                  (template) => template.id === selectedTemplate
                );
                const messageContents = selectedStudents.map(
                  (student, index) => {
                    return {
                      to: students.find((std) => std.id === student)
                        .phoneNumber,
                      content: transformVariable(contents, index),
                    };
                  }
                );
                const formData = {};
                const kind =
                  template && template.custom === 0 ? "kakao" : "sms";
                formData["students"] = selectedStudents;
                formData["kind"] = kind;
                formData["messageContents"] = messageContents;
                if (template) {
                  formData["templateId"] = template.id ?? undefined;
                  formData["templateCode"] = template.templateCode ?? undefined;
                }
                const response = await fetch(
                  DOMAINCONSTRUCTOR("message_send"),
                  {
                    method: "POST",
                    headers: {
                      "Content-Type": "application/json",
                    },
                    body: JSON.stringify(formData),
                  }
                ).then((res) => {
                  return res.json();
                });

                if (response.statusCode === "202") {
                  messageApi.success(
                    `메시지가 전송 요청되었습니다.\n전송 결과는 메시지함에서 확인하실 수 있습니다.`
                  );
                  setContents("");
                  setSelectedStudents([]);
                  setSelectedTemplate(null);
                  setIsGsMode(false);
                  setSelectedGrade(null);
                  setContentAreDisabled(true);
                } else {
                  messageApi.error(`메시지 전송에 실패했습니다.`);
                }
              }}
            >
              전송
            </Button>
          </Space>
        </Space>,
      ]}
    >
      {contextHolder}
      {alert && alertMessage && (
        <Alert message={alertMessage} type="error" showIcon />
      )}

      <Modal
        title="템플릿 관리"
        open={templateModal}
        maskClosable={false}
        onCancel={() => setTemplateModal(false)}
        footer={null}
      >
        <Space
          style={{
            width: "100%",
          }}
          direction="vertical"
        >
          <Space
            style={{
              width: "100%",
              justifyContent: "flex-end",
            }}
          >
            <Typography.Text>
              {messageTemplates.filter((item) => item.custom === 1).length} / 10
            </Typography.Text>
          </Space>
          <List
            bordered
            dataSource={messageTemplates.filter((item) => item.custom === 1)}
            renderItem={(item) => (
              <List.Item
                actions={[
                  <Tooltip title="삭제">
                    <Button
                      onClick={() => {
                        Modal.confirm({
                          title: "정말 삭제하시겠습니까?",
                          icon: <ExclamationCircleOutlined />,
                          content: "삭제된 템플릿은 복구할 수 없습니다.",
                          okText: "삭제",
                          okType: "danger",
                          cancelText: "취소",
                          onOk() {
                            fetch(
                              `${DOMAINCONSTRUCTOR("message_templates")}/${
                                item.id
                              }`,
                              {
                                method: "DELETE",
                                credentials: "include",
                              }
                            ).then((res) => {
                              if (res.status === 200) {
                                setContents("");
                                setSelectedTemplate(null);
                                setContentAreDisabled(true);
                                fetchMessageTemplats();
                                messageApi.success("템플릿이 삭제되었습니다.");
                              } else {
                                messageApi.error("템플릿 삭제에 실패했습니다.");
                              }
                            });
                          },
                          onCancel() {},
                        });
                      }}
                      icon={<DeleteOutlined />}
                      type="text"
                      danger
                    />
                  </Tooltip>,
                ]}
              >
                <Typography.Text>{item.templateTitle}</Typography.Text>
              </List.Item>
            )}
          />
        </Space>
      </Modal>
      <Modal
        title="템플릿 저장"
        open={
          templateSaveModal && (session.messageAuth === 2 || session.type === 1)
        }
        maskClosable={false}
        onCancel={() => setTemplateSaveModal(false)}
        footer={[
          <Button
            disabled={!templateTitle || !contents}
            type="primary"
            onClick={() => {
              saveTemplate();
            }}
          >
            저장
          </Button>,
        ]}
      >
        <Space
          style={{
            width: "100%",
          }}
          direction="vertical"
        >
          <Card>
            <Space
              style={{
                width: "100%",
              }}
              direction="vertical"
            >
              <Typography.Title
                style={{
                  margin: 0,
                }}
                level={5}
              >
                템플릿 제목
              </Typography.Title>
              <TextArea
                autoSize
                value={templateTitle}
                onChange={(event) => {
                  setTemplateTitle(event.target.value);
                }}
                showCount
                maxLength={20}
              />
              <Typography.Title
                style={{
                  margin: 0,
                }}
                level={5}
              >
                내용
              </Typography.Title>
              <TextArea
                disabled
                autoSize
                value={contents}
                onChange={(event) => {
                  setContents(event.target.value);
                }}
                showCount
                maxLength={1000}
              />
            </Space>
          </Card>
        </Space>
      </Modal>
      <Space
        style={{
          width: "100%",
        }}
        direction="vertical"
      >
        <Select
          style={{
            width: "100%",
          }}
          placeholder="메시지 수신자 선택"
          mode="multiple"
          virtual={true}
          optionFilterProp="title"
          optionRender={(option) => {
            return (
              <Space
                direction="horizontal"
                style={{
                  width: "100%",
                }}
              >
                <div
                  style={{
                    width: 50,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {option.data.hasScoreToday ? (
                    <Badge bg="success">출석</Badge>
                  ) : (
                    <Badge bg="danger">미출석</Badge>
                  )}
                </div>
                <span value={option.label}>{option.label}</span>
              </Space>
            );
          }}
          value={selectedStudents}
          onChange={(stu) => {
            if (stu.length >= 100) {
              messageApi.error("100명 이상의 수신자를 선택할 수 없습니다.");
              return;
            }
            setSelectedStudents(stu);
          }}
          maxCount={100}
          suffixIcon={suffix}
          options={students.map((student) => {
            return {
              value: student.id,
              label: `[${
                student.type === 1
                  ? "최고관리자"
                  : student.type === 2
                  ? "선생님"
                  : "학생"
              }]${student.name} (${student.userid}) ${
                student.phoneNumber === ""
                  ? "(등록된 연락처 없음)"
                  : `(${student.phoneNumber})`
              }`,
              title: `${student.name} (${student.userid}) ${
                student.phoneNumber === ""
                  ? "(등록된 연락처 없음)"
                  : `(${student.phoneNumber})`
              }`,
              disabled: student.phoneNumber === "",
              hasScoreToday: student.hasScoreToday,
            };
          })}
          status={selectedStudents.length === 0 ? "error" : "normal"}
        />
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <Select
            style={{ width: "49%" }}
            options={[
              ...messageTemplates.map((template) => {
                return {
                  value: template.id,
                  label: `${template.custom === 0 ? "[기본]" : "[커스텀]"}${
                    template.templateTitle
                  }`,
                };
              }),
              session.messageAuth === 2 || session.type === 1
                ? {
                    value: "-1",
                    label: "직접 작성",
                  }
                : null,
            ].filter((option) => option !== null)}
            value={selectedTemplate}
            onChange={handleChangeTemplate}
            optionLabelProp="label"
            placeholder="저장된 메시지 템플릿 선택"
            disabled={selectedStudents?.length === 0}
          />
          <Select
            style={{
              width: "49%",
              visibility: isGsMode ? "visible" : "hidden",
            }}
            options={gs.map((gs, index) => {
              return {
                value: gs,
                label: `${gs}급`,
              };
            })}
            onChange={(value) => {
              setSelectedGrade(value);
              setContentAreDisabled(true);
              const selectedTemplate = messageTemplates.find((template) =>
                template.templateTitle.includes("승급")
              );
              setContents(selectedTemplate.templateContent);
            }}
            value={selectedGrade}
            optionLabelProp="label"
            placeholder="급수 선택"
            disabled={selectedStudents?.length === 0}
          />
        </div>
        <Space
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography.Title level={5}>메시지 내용</Typography.Title>
          <Space>
            <Button
              disabled={selectedStudents?.length === 0 || contentAreDisabled}
              ref={teacherTarget}
              onClick={() => addingValue("{학원이름}")}
            >
              학원이름
            </Button>
            <Button
              disabled={selectedStudents?.length === 0 || contentAreDisabled}
              ref={teacherTarget}
              onClick={() => addingValue("{선생님}")}
            >
              선생님이름
            </Button>

            <Button
              disabled={selectedStudents?.length === 0 || contentAreDisabled}
              ref={studentTarget}
              onClick={() => addingValue("{학생}")}
            >
              학생이름
            </Button>
          </Space>
        </Space>
        <TextArea
          style={{
            marginBottom: 20,
          }}
          ref={messageRef}
          count={{
            show: true,
            max: 1000,
          }}
          disabled={
            selectedStudents?.length === 0 ||
            (isGsMode && !selectedGrade) ||
            contentAreDisabled
          }
          autoSize
          onChange={(event) => {
            setContents(event.target.value);
          }}
          value={contents}
        />
        <Space
          style={{
            width: "100%",
          }}
          direction="vertical"
        >
          <Typography.Title
            style={{
              margin: 0,
            }}
            level={5}
          >
            미리보기
          </Typography.Title>
          <Space
            style={{
              width: "100%",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Button
              type="text"
              icon={<LeftOutlined />}
              onClick={() => navigateStudent(-1)}
              disabled={studentIndex === 0 || selectedStudents.length === 0}
            />
            <Typography.Text
              style={{
                margin: 0,
              }}
              level={5}
            >
              {selectedStudents.length !== 0
                ? students.find(
                    (std) => std.id === selectedStudents[studentIndex]
                  ).name
                : ""}
            </Typography.Text>
            <Button
              type="text"
              icon={<RightOutlined />}
              onClick={() => navigateStudent(1)}
              disabled={
                studentIndex === selectedStudents.length - 1 ||
                selectedStudents.length === 0
              }
            />
          </Space>
        </Space>

        <TextArea
          disabled
          rows={5}
          value={
            selectedStudents.length !== 0
              ? transformVariable(contents, studentIndex)
              : ""
          }
          autoSize
        />
      </Space>
    </Modal>
  );
};
export default MessageModal;
