import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { userSession } from "./atom/atoms";
import { DOMAINCONSTRUCTOR } from "./API/DOMAINS";

const NavBar = () => {
  const navigate = useNavigate();
  const [session, setSesstion] = useRecoilState(userSession);

  const hanldleClick = (path) => {
    navigate(path);
  };

  const logout = async () => {
    await fetch(DOMAINCONSTRUCTOR("logout"), {
      method: "GET",
    })
      .then((res) => {
        if (res.status === 200) {
          return res.json().then((res) => {
            if (res.result === "success") {
              setSesstion({});
              navigate("/");
            }
          });
        } else {
          return res.json().then((res) => {
            throw new Error(
              "로그아웃 과정에서 오류가 발생했습니다." + res.error_message
            );
          });
        }
      })
      .catch((err) => {
        alert(err.message);
      });
  };

  return (
    <Navbar
      collapseOnSelect
      expand="md"
      className="bg-body-tertiary"
      onSelect={hanldleClick}
    >
      <StyleContainer>
        <Navbar.Brand>
          <Nav.Link eventKey="/">주산암산 Flash</Nav.Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse
          id="responsive-navbar-nav"
          className={
            session.isAuth ? "justify-content-between" : "justify-content-end"
          }
        >
          {session.isAuth && (
            <Nav className="me-auto">
              <Nav.Link eventKey="register">회원등록</Nav.Link>
              <Nav.Link eventKey="user">학생정보</Nav.Link>
              <Nav.Link eventKey="exam">급수시험</Nav.Link>
              {session.messageAuth && session.messageAuth !== 0 ? (
                <Nav.Link eventKey="message">메시지</Nav.Link>
              ) : null}
              {session.type === 1 ? (
                <Nav.Link eventKey="game">대회관리</Nav.Link>
              ) : null}
            </Nav>
          )}
          <Nav>
            {session.isAuth ? (
              <>
                <Nav.Link eventKey={`user/info/${session.memberId}`}>
                  <b>{session?.username}</b>님 환영합니다!
                  {/* 선생님-나만의 개인정보 여기로 통합 */}
                </Nav.Link>
                <Nav.Link onClick={() => logout()}>로그아웃</Nav.Link>
              </>
            ) : (
              <Nav.Link eventKey={"login"}>로그인</Nav.Link>
            )}
          </Nav>
        </Navbar.Collapse>
      </StyleContainer>
    </Navbar>
  );
};
const StyleContainer = styled(Container)`
  justify-content: space-between;
  max-width: 100%;
`;

export default NavBar;
