import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { RecoilRoot } from "recoil";

import Home from "./Home";
import Register from "./user/Register";
import Login from "./auth/login";
import Score from "./score/score";
import ScoreView from "./score/scoreView";
import ResultView from "./game/ResultView";
import ExamList from "./exam/ExamList";
import Upload from "./game/Upload";
import GameList from "./game/GameList";
import UserInfo from "./user/UserInfo";
import User from "./user/User";
import ExamPrize from "./game/ExamPrize";

import "./assets/fonts/Font.css";
import GamePrize from "./game/GamePrize";
import Message from "./message/message";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <RecoilRoot>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<App />}>
            <Route index element={<Home />} />
            <Route path="login" element={<Login />} />
            <Route path="register" element={<Register />} />
            <Route path="user" element={<User />} />
            <Route path="user/info/:memberId" element={<UserInfo />} />
            <Route path="score" element={<Score />} />
            <Route path="score/view/:memberId" element={<ScoreView />} />
            <Route path="game" element={<GameList />} />
            <Route path="game/upload" element={<Upload />} />
            <Route path="exam" element={<ExamList />} />
            <Route path="message" element={<Message />} />
          </Route>
          <Route path="game/examPrize" element={<ExamPrize />} />
          <Route path="game/gamePrize" element={<GamePrize />} />
          <Route path="resultView" element={<ResultView />} />
        </Routes>
      </BrowserRouter>
    </RecoilRoot>
  </React.StrictMode>
);

reportWebVitals();
