import { useState, useEffect } from "react";
import { styled } from "styled-components";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";
import Form from "react-bootstrap/Form";
import { getKorDate } from "../date/getKorDate";
import { DOMAINCONSTRUCTOR } from "../API/DOMAINS";
import { InputGroup } from "react-bootstrap";
import useAuth from "../hooks/useAuth";

const GameList = () => {
  const [data, setData] = useState([]);
  const [titleEditMode, setTitleEditMode] = useState([]);
  const navigate = useNavigate();
  const session = useAuth();

  const fetchListAPI = () => {
    fetch(DOMAINCONSTRUCTOR("game"))
      .then((response) => response.json())
      .then((response) => {
        setData(response.data);
        setTitleEditMode(Array(response.data.length).fill(false));
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    fetchListAPI();
  }, []);
  const deleteItem = async (id) => {
    if (!window.confirm("정말로 삭제하시겠습니까?")) return;
    await fetch(`${DOMAINCONSTRUCTOR("game")}/${id}`, {
      method: "DELETE",
    })
      .then((res) => {
        if (res.status === 200) {
          alert("정상적으로 삭제되었습니다.");
        } else {
          alert("오류가 발생하였습니다.");
        }
      })
      .then(() => fetchListAPI())
      .catch((error) => {
        console.log(error);
        alert("오류가 발생하였습니다.\n", error);
      });
  };
  const setVisible = async (idx) => {
    await fetch(`${DOMAINCONSTRUCTOR("game_visible")}/${data[idx].id}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        visible: data[idx].visible === 1 ? false : true,
      }),
    })
      .then((res) => {
        if (res.status === 200) {
          alert(
            "정상적으로 " +
              (data[idx].visible === 1 ? "숨김 " : "공개 ") +
              "처리되었습니다."
          );
        } else {
          alert("오류가 발생하였습니다.");
        }
      })
      .then(() => fetchListAPI())
      .catch((error) => {
        console.log(error);
        alert("오류가 발생하였습니다.", error);
      });
  };

  const onClickResult = (idx) => {
    window.open(
      `/resultView?type=${
        data[idx].filename === "경기대회결과" ? "game" : "exam"
      }&id=${data[idx].id}&date=${getKorDate(data[idx].date)}`,
      "",
      "width=900, height=1200"
    );
  };
  const handleDownload = (id, type) => {
    window.open(
      `${DOMAINCONSTRUCTOR("game_download_excel_file")}/${type}/${id}`,
      "_blank"
    );
  };
  const onEditTitle = (id) => {
    setTitleEditMode((prev) => {
      const temp = [...prev];
      temp[id] = !temp[id];
      return temp;
    });
  };
  const onChageTitle = (id) => {
    const title = document.getElementById(`title${id}`).value;
    fetch(`${DOMAINCONSTRUCTOR("game_title")}/${id}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        title: title,
      }),
    })
      .then((res) => {
        if (res.status === 200) {
          alert("정상적으로 변경되었습니다.");
        } else {
          alert("오류가 발생하였습니다.");
        }
      })
      .then(() => fetchListAPI())
      .catch((error) => {
        console.log(error);
        alert("오류가 발생하였습니다.", error);
      });
  };
  if (session.type !== 1) navigate("/");
  return (
    <Container>
      <StyleContainer>
        <Row>
          <h2>결과 조회</h2>
        </Row>
        <Row>
          <Col xs={9}></Col>
          <Col xs={3}>
            <Button
              onClick={() => navigate("/game/upload")}
              style={{ float: "right" }}
              variant="primary"
            >
              결과파일 업로드하기
            </Button>
          </Col>
        </Row>
      </StyleContainer>
      <StyleContainer>
        <div className="table-responsive">
          <table className="table table-bordered align-middle table-hover">
            <thead>
              <tr className="thead-dark">
                <StyleTh>차수</StyleTh>
                <StyleTh>날짜</StyleTh>
                <StyleTh>구분</StyleTh>
                <StyleTh>표시 이름</StyleTh>
                <StyleTh>관리</StyleTh>
              </tr>
            </thead>
            <tbody>
              {data.length !== 0 ? (
                data.map((item, idx) => {
                  return (
                    <tr
                      key={idx}
                      className={!item["visible"] && "table-secondary"}
                    >
                      <StyleTd>{item["round"]}</StyleTd>
                      <StyleTd>
                        {item["date"] && getKorDate(item["date"])}
                      </StyleTd>
                      <StyleTd>{item["filename"]}</StyleTd>
                      {titleEditMode[idx] ? (
                        <StyleTd>
                          <InputGroup>
                            <Form.Control
                              id={`title${item["id"]}`}
                              style={{
                                minWidth: "150px",
                                margin: "0 auto",
                              }}
                              type="text"
                              defaultValue={item["title"]}
                            />
                            <StyleButton
                              variant="primary"
                              onClick={() => onChageTitle(item["id"])}
                            >
                              변경
                            </StyleButton>
                          </InputGroup>
                        </StyleTd>
                      ) : (
                        <StyleTd>{item["title"]} </StyleTd>
                      )}
                      <StyleTd>
                        <StyleButton
                          variant="outline-danger"
                          onClick={() => onEditTitle(idx)}
                        >
                          {titleEditMode[idx] ? "변경 취소" : "이름 변경"}
                        </StyleButton>
                        <StyleButton
                          variant="outline-primary"
                          onClick={() => onClickResult(idx)}
                        >
                          결과 확인
                        </StyleButton>
                        <StyleButton
                          variant={
                            item["visible"] ? "outline-secondary" : "primary"
                          }
                          onClick={() => setVisible(idx)}
                        >
                          {item["visible"] ? "숨김" : "공개"} 처리
                        </StyleButton>
                        <StyleButton
                          variant="outline-success"
                          onClick={() =>
                            handleDownload(
                              item["id"],
                              item["filename"] === "경기대회결과"
                                ? "game"
                                : "exam"
                            )
                          }
                        >
                          다운로드
                        </StyleButton>
                        <StyleButton
                          variant="danger"
                          onClick={() => deleteItem(item["id"])}
                        >
                          삭제
                        </StyleButton>
                      </StyleTd>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <StyleTd colSpan="5" style={{ textAlign: "center" }}>
                    등록된 성적이 없습니다.
                  </StyleTd>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </StyleContainer>
    </Container>
  );
};

export default GameList;

const StyleContainer = styled(Container)`
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

const StyleTh = styled.th`
  text-align: center;
  white-space: nowrap;
`;

const StyleTd = styled.td`
  text-align: center;
  white-space: nowrap;
`;

const StyleButton = styled(Button)`
  margin-left: 2.5px;
  margin-right: 2.5px;
`;
