import { useEffect } from "react";
import { useRecoilValue } from "recoil";
import { userSession } from "../atom/atoms";
import { useNavigate } from "react-router-dom";

const useAuth = () => {
  const session = useRecoilValue(userSession);
  const navigate = useNavigate();

  useEffect(() => {
    if (!session.isAuth) {
      alert("로그인이 필요합니다.");
      navigate("/login");
    }
  }, [session.isAuth, navigate]);

  return session;
};

export default useAuth;
