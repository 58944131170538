import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { styled } from "styled-components";
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  Accordion,
  Spinner,
  Pagination,
} from "react-bootstrap";
import { DOMAINCONSTRUCTOR } from "../API/DOMAINS";
import { useRecoilState } from "recoil";
import { scorePageLimitAtom } from "../atom/atoms";
import useAuthentication from "../hooks/useAuthentication";
import CustomPagination from "../API/pagination";
import { getKorFullDate } from "../date/getKorDate";
import { Dropdown, Space } from "antd";

const ScoreView = () => {
  const memberId = useParams().memberId;
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const isAuth = useAuthentication();
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const [pageLimit, setPageLimit] = useRecoilState(scorePageLimitAtom);
  const [pageList, setPageList] = useState([]);
  const [mode, setMode] = useState(0);
  const [name, setName] = useState("");
  const download = async (period) => {
    window.open(
      DOMAINCONSTRUCTOR("score") + `/download/${memberId}/${period}`,
      "_blank"
    );
  };
  const items = [
    {
      key: "today",
      label: <div onClick={() => download("today")}>오늘</div>,
    },
    {
      key: "week",
      label: <div onClick={() => download("week")}>최근 1주</div>,
    },
    {
      key: "month",
      label: <div onClick={() => download("month")}>최근 한 달</div>,
    },
    {
      key: "all",
      label: <div onClick={() => download("all")}>전체</div>,
    },
  ];

  useEffect(() => {
    setPageList(CustomPagination(page, setPage, total, pageLimit, null));
  }, [page, setPage, total, pageLimit]);
  const [accordionStates, setAccordionStates] = useState(
    Array(pageLimit).fill(false)
  );
  const [displayState, setDisplayState] = useState(
    Array(pageLimit).fill("none")
  );
  const [accordionContent, setAccordionContent] = useState(
    Array(pageLimit).fill(null)
  );
  const [accordionLoading, setAccordionLoading] = useState(
    Array(pageLimit).fill(false)
  );
  useEffect(() => {
    setAccordionStates(Array(pageLimit).fill(false));
    setDisplayState(Array(pageLimit).fill("none"));
    setAccordionContent(Array(pageLimit).fill(null));
    setAccordionLoading(Array(pageLimit).fill(false));
  }, [page, pageLimit]);

  useEffect(() => {
    const getScoreData = async () => {
      if (page === null) return;
      await fetch(
        DOMAINCONSTRUCTOR("score") +
          `/${memberId}/${mode}/${page}/${pageLimit}`,
        {
          method: "GET",
        }
      ).then((res) => {
        if (res.status === 200) {
          return res.json().then((res) => {
            setData(res.data);
            setTotal(res.total);
            setName(res.name);
          });
        } else {
          alert("성적정보를 가져오는데 실패했습니다.");
        }
      });
    };
    getScoreData();
  }, [memberId, mode, page, pageLimit]);
  const getDetailScoreData = (index) => {
    fetch(DOMAINCONSTRUCTOR("score") + `/${memberId}/${data[index].no}`)
      .then((res) => res.json())
      .then((res) => {
        if (!res.data) {
          const newAccordionContent = [...accordionContent];
          newAccordionContent[index] = null;
          setAccordionContent(newAccordionContent);
          const newAccordionStates = [...accordionStates];
          newAccordionStates[index] = !newAccordionStates[index];
          setAccordionStates(newAccordionStates);
          const newAccordionLoading = [...accordionLoading];
          newAccordionLoading[index] = false;
          setAccordionLoading(newAccordionLoading);
          changeDisplay(index);
          return;
        }
        const problemInfoRaw = res.data.problemInfo;
        const problemInfoArray = problemInfoRaw.split("@");
        const problemInfo = problemInfoArray.slice(0, -1).map((item) => {
          const problemNumber = item.split("#")[0];
          const problemResult = item.split("#")[1];
          const problem = item.split("#")[2];
          const problemAnswer = item.split("#")[3];
          const problemUserAnswer = item.split("#")[4];
          return {
            problemNumber: problemNumber,
            problemResult: problemResult,
            problem: problem,
            problemAnswer: problemAnswer,
            problemUserAnswer: problemUserAnswer,
          };
        });
        const newAccordionContent = [...accordionContent];
        newAccordionContent[index] = problemInfo;
        setAccordionContent(newAccordionContent);
        const newAccordionStates = [...accordionStates];
        newAccordionStates[index] = !newAccordionStates[index];
        setAccordionStates(newAccordionStates);
        const newAccordionLoading = [...accordionLoading];
        newAccordionLoading[index] = false;
        setAccordionLoading(newAccordionLoading);
        changeDisplay(index);
      });
  };
  const toggleAccordion = (index) => {
    if (accordionContent[index]) {
      const newAccordionStates = [...accordionStates];
      newAccordionStates[index] = !newAccordionStates[index];
      setAccordionStates(newAccordionStates);
      changeDisplay(index);
      return;
    } else {
      setAccordionLoading(true);
      getDetailScoreData(index);
    }
  };

  const changeDisplay = (index) => {
    const newDisplayState = [...displayState];
    if (newDisplayState[index] === "") {
      const timeout = setTimeout(() => {
        newDisplayState[index] = "none";
        setDisplayState(newDisplayState);
      }, 300);

      return () => clearTimeout(timeout);
    } else {
      newDisplayState[index] = "";
      setDisplayState(newDisplayState);
    }
  };
  if (!isAuth) return null;
  return (
    <Container>
      <StyleContainer>
        <Row>{name && <h2>{`${name}님 성적보기`}</h2>}</Row>
        <Row style={{ marginTop: "1rem" }}>
          <Col xs={4}>
            <Space>
              <Button variant="primary" onClick={() => navigate(-1)}>
                돌아가기
              </Button>
              <Dropdown menu={{ items }} trigger={["click"]}>
                <Button>성적 다운로드</Button>
              </Dropdown>
            </Space>
          </Col>
          <Col xs={2} />
          <Col xs={3}>
            <Form.Select
              value={pageLimit}
              onChange={(e) => {
                setPageLimit(Number(e.target.value));
                setPage(1);
              }}
            >
              <option value={15}>15개씩 보기</option>
              <option value={30}>30개씩 보기</option>
              <option value={50}>50개씩 보기</option>
              <option value={100}>100개씩 보기</option>
            </Form.Select>
          </Col>
          <Col xs={3}>
            <Form.Select value={mode} onChange={(e) => setMode(e.target.value)}>
              <option value={0}>전체</option>
              <option value={1}>급수모드</option>
              <option value={2}>자유모드</option>
              <option value={3}>독산모드</option>
              <option value={4}>급수시험모드</option>
            </Form.Select>
          </Col>
        </Row>
      </StyleContainer>
      <StyleContainer>
        <div className="table-responsive-lg">
          <Accordion />
          <table className="table table-bordered align-middle">
            <thead>
              <tr className="thead-dark">
                <StyleTh>모드</StyleTh>
                <StyleTh>점수</StyleTh>
                <StyleTh>플레이시간</StyleTh>
                <StyleTh>문제수</StyleTh>
                <StyleTh>행수</StyleTh>
                <StyleTh>범위</StyleTh>
                <StyleTh>뺄셈빈도</StyleTh>
                <StyleTh>시간</StyleTh>
                <StyleTh>상세보기</StyleTh>
              </tr>
            </thead>
            <tbody>
              {data.length > 0 ? (
                data.map((item, idx) => [
                  <>
                    <tr className="accordion-header" id={idx} key={idx}>
                      <StyleTd>{item["kind"]}</StyleTd>
                      <StyleTd>{item["score"]}</StyleTd>
                      <StyleTd>{item["studyTime"]}</StyleTd>
                      <StyleTd>
                        {item["problemNum"] !== -1
                          ? item["problemNum"]
                          : "없음"}
                      </StyleTd>
                      <StyleTd>
                        {item["rowNum"] !== -1 ? item["rowNum"] : "없음"}
                      </StyleTd>
                      <StyleTd>
                        {item["rangeNum"] !== "" ? item["rangeNum"] : "없음"}
                      </StyleTd>
                      <StyleTd>
                        {item["frequency"] !== -1 ? item["frequency"] : "없음"}
                      </StyleTd>
                      <StyleTd>
                        {item["insertTime"] &&
                          getKorFullDate(item["insertTime"])}
                      </StyleTd>
                      <StyleTd>
                        {accordionLoading[idx] ? (
                          <Spinner animation="border" variant="primary" />
                        ) : (
                          <Button
                            variant="outline-secondary"
                            onClick={() => toggleAccordion(idx)}
                            style={{ width: "40px" }}
                          >
                            {accordionStates[idx] ? "-" : "+"}
                          </Button>
                        )}
                      </StyleTd>
                    </tr>
                    <tr
                      style={{
                        display: displayState[idx],
                        opacity: accordionStates[idx] ? "1" : "0",
                        transition: "opacity 0.3s ease-out",
                      }}
                    >
                      <td colSpan="11">
                        <Accordion activeKey={accordionStates[idx] ? "0" : ""}>
                          <Accordion.Item eventKey="0">
                            <Accordion.Body>
                              {accordionContent[idx] ? (
                                <table className="table table-bordered align-middle">
                                  <thead>
                                    <tr className="thead-dark">
                                      <StyleTh>번호</StyleTh>
                                      <StyleTh>결과</StyleTh>
                                      <StyleTh>문제</StyleTh>
                                      <StyleTh>정답</StyleTh>
                                      <StyleTh>쓴답</StyleTh>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {accordionContent[idx]?.map(
                                      (accordionItem, accordionIdx) => (
                                        <tr key={accordionIdx}>
                                          <StyleTd>
                                            {accordionItem["problemNumber"]}
                                          </StyleTd>
                                          <StyleTd
                                            style={{
                                              backgroundColor:
                                                accordionItem[
                                                  "problemResult"
                                                ] === "O"
                                                  ? "#BEEFFF"
                                                  : "#CD7A7A",
                                            }}
                                          >
                                            {accordionItem["problemResult"]}
                                          </StyleTd>
                                          <StyleTd>
                                            {accordionItem["problem"]}
                                          </StyleTd>
                                          <StyleTd>
                                            {accordionItem["problemAnswer"]}
                                          </StyleTd>
                                          <StyleTd>
                                            {accordionItem["problemUserAnswer"]}
                                          </StyleTd>
                                        </tr>
                                      )
                                    )}
                                  </tbody>
                                </table>
                              ) : (
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  <span>해당 성적의 상세정보가 없습니다.</span>
                                </div>
                              )}
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      </td>
                    </tr>
                  </>,
                ])
              ) : (
                <tr>
                  <td colSpan="11">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <span>성적 데이터가 없습니다.</span>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <Row>
          <Col></Col>
          <Col style={{ display: "flex", justifyContent: "center" }}>
            {data.length > 0 && <Pagination>{pageList}</Pagination>}
          </Col>
          <Col></Col>
        </Row>
      </StyleContainer>
    </Container>
  );
};

export default ScoreView;

const StyleContainer = styled(Container)`
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

const StyleTd = styled.td`
  text-align: center;
  white-space: nowrap;
`;
const StyleTh = styled.th`
  text-align: center;
  white-space: nowrap;
`;
