import { useState, useEffect } from "react";
import styled from "styled-components";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import useAuthentication from "../hooks/useAuth";
import Tab from "react-bootstrap/Tab";
import Container from "react-bootstrap/Container";
import Pagination from "react-bootstrap/Pagination";
import CustomPagination from "../API/pagination";
import Tabs from "react-bootstrap/Tabs";
import Badge from "react-bootstrap/Badge";
import { DOMAINCONSTRUCTOR } from "../API/DOMAINS";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import { userPageLimitAtom, userPageAtom, modeAtom } from "../atom/atoms";
import { InputGroup, Button } from "react-bootstrap";
import { getKorDate } from "../date/getKorDate";

const User = () => {
  const [pageLimit, setPageLimit] = useRecoilState(userPageLimitAtom);
  const [page, setPage] = useState(null);
  const [total, setTotal] = useState(0);
  const isAuth = useAuthentication();
  const [data, setData] = useState([]);
  const [mode, setMode] = useRecoilState(modeAtom);
  const [searchParams] = useSearchParams();
  const searchText = searchParams.get("searchText");
  const handleTabClick = (mode) => {
    setMode(mode);
    setPage(1);
  };
  useEffect(() => {
    const getMemberData = async () => {
      if (page === null) return;
      await fetch(
        DOMAINCONSTRUCTOR("user_list") +
          `/${mode === "discharge" ? 1 : 0}/${page}/${pageLimit}?searchText=${
            searchText ?? ""
          }`,
        {
          method: "GET",
          credentials: "include",
        }
      ).then((res) => {
        if (res.status === 200) {
          return res.json().then((res) => {
            setTotal(res.total);
            setData(res.data);
          });
        } else {
          // alert("회원정보를 가져오는데 실패했습니다.");
        }
      });
    };
    getMemberData();
  }, [mode, page, pageLimit, searchText]);
  if (!isAuth) return null;
  return (
    <StyleContainer>
      <StyleRow>
        <h2>학생관리</h2>
      </StyleRow>
      <StyleRow>
        <Tabs
          id="controlled-tab-example"
          activeKey={mode}
          onSelect={handleTabClick}
          className="mb-3"
        >
          <Tab eventKey="student" title="학생관리">
            <PaneView
              mode={mode}
              data={data}
              total={total}
              page={page}
              setPage={setPage}
              pageLimit={pageLimit}
              setPageLimit={setPageLimit}
              searchText={searchText}
            />
          </Tab>
          <Tab eventKey="discharge" title="퇴소생관리">
            <PaneView
              mode={mode}
              data={data}
              total={total}
              page={page}
              setPage={setPage}
              pageLimit={pageLimit}
              setPageLimit={setPageLimit}
              searchText={searchText}
            />
          </Tab>
        </Tabs>
      </StyleRow>
    </StyleContainer>
  );
};

const PaneView = ({
  mode,
  data,
  total,
  page,
  setPage,
  pageLimit,
  setPageLimit,
  setSearch,
  searchText,
}) => {
  const [selectAll, setSelectAll] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [pageList, setPageList] = useState([]);
  const [savePage, setSavePage] = useRecoilState(userPageAtom);
  const [inputValue, setInputValue] = useState("");
  // useEffect(() => {
  //   if (savePage === page) return;
  //   if (savePage === null) return;
  //   setPage(savePage);
  //   setSavePage(null);
  // }, [savePage]);
  useEffect(() => {
    setPage(savePage);
  }, []);
  const navigate = useNavigate();
  useEffect(() => {
    setSelectedItems([]);
    setSelectAll(false);
  }, [mode]);
  const onSubmitHandler = (e) => {
    e.preventDefault();
    setPage(1);
    navigate(`/user?searchText=${e.target.searchText.value}`);
  };

  useEffect(() => {
    setPageList(CustomPagination(page, setPage, total, pageLimit, setSavePage));
  }, [page, setPage, total, pageLimit, setSavePage]);
  const getCurrentPageData = () => {
    return data.map((item) => item.userid);
  };
  const handleSelectAll = () => {
    if (!selectAll) {
      setSelectedItems(getCurrentPageData());
    } else {
      setSelectedItems([]);
    }
    setSelectAll(!selectAll);
  };

  const handleSelectItem = (userid) => {
    const selectedIndex = selectedItems.indexOf(userid);
    if (selectedIndex === -1) {
      setSelectedItems([...selectedItems, userid]);
    } else {
      const updatedSelectedItems = [...selectedItems];
      updatedSelectedItems.splice(selectedIndex, 1);
      setSelectedItems(updatedSelectedItems);
    }
  };
  const handleRemove = () => {
    if (selectedItems.length === 0) {
      alert("선택된 회원이 없습니다.");
      return;
    }
    if (!window.confirm("선택된 회원을 삭제하시겠습니까?")) {
      return;
    }
    if (
      !window.confirm("삭제된 회원은 복구할 수 없습니다. 계속하시겠습니까?")
    ) {
      return;
    }

    const formData = {};
    formData.users = selectedItems;
    fetch(DOMAINCONSTRUCTOR("user_list_remove"), {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    }).then((res) => {
      if (res.status === 200) {
        alert("삭제가 완료되었습니다.");
        window.location.reload();
      } else {
        alert("삭제에 실패했습니다." + res.error_message);
      }
    });
  };
  const handleDischarge = () => {
    if (selectedItems.length === 0) {
      alert("선택된 회원이 없습니다.");
      return;
    }
    if (
      !window.confirm(
        "선택된 회원을 퇴소처리 하시겠습니까?\n퇴소처리된 회원은 재입소 처리가 가능합니다."
      )
    ) {
      return;
    }

    const formData = {};
    formData.users = selectedItems;
    formData.endDate = getKorDate();
    fetch(DOMAINCONSTRUCTOR("user_list"), {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    }).then((res) => {
      if (res.status === 200) {
        alert("퇴소처리가 완료되었습니다.");
        window.location.reload();
      } else {
        alert("퇴소처리에 실패했습니다." + res.error_message);
      }
    });
  };

  const handleResign = () => {
    if (mode === "student") return;

    if (selectedItems.length === 0) {
      alert("선택된 회원이 없습니다.");
      return;
    }
    if (!window.confirm("선택된 회원을 재입소처리 하시겠습니까?")) {
      return;
    }

    const formData = {};
    formData.users = selectedItems;
    fetch(DOMAINCONSTRUCTOR("user_list_resign"), {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    }).then((res) => {
      if (res.status === 200) {
        alert("재입소처리가 완료되었습니다.");
        window.location.reload();
      } else {
        alert("재입소처리에 실패했습니다." + res.error_message);
      }
    });
  };
  useEffect(() => {
    setInputValue(searchText);
  }, [searchText]);
  return (
    <>
      <Row className="mb-3">
        <Col>
          {mode === "student" && (
            <Button
              variant="danger"
              onClick={handleDischarge}
              disabled={selectedItems.length === 0}
            >
              퇴소처리
            </Button>
          )}
          {mode === "discharge" && (
            <>
              <Button
                variant="outline-primary"
                onClick={handleResign}
                style={{ marginRight: "1rem" }}
                disabled={selectedItems.length === 0}
              >
                재입소 처리
              </Button>
              <Button
                variant="danger"
                onClick={handleRemove}
                disabled={selectedItems.length === 0}
              >
                완전삭제
              </Button>
            </>
          )}
        </Col>
        <Col></Col>
        <Col>
          <Form.Select
            value={pageLimit}
            onChange={(e) => {
              setPageLimit(Number(e.target.value));
              setPage(1);
            }}
          >
            <option value={15}>15명씩 보기</option>
            <option value={30}>30명씩 보기</option>
            <option value={50}>50명씩 보기</option>
            <option value={100}>100명씩 보기</option>
          </Form.Select>
        </Col>
        <Col>
          <Form onSubmit={onSubmitHandler}>
            <InputGroup>
              <Form.Control
                type="text"
                placeholder="검색"
                name="searchText"
                defaultValue={inputValue}
              />
              <Button type="submit" variant="outline-secondary">
                검색
              </Button>
            </InputGroup>
          </Form>
        </Col>
      </Row>
      <Col xs={12} className="table-responsive">
        <table className="table table-bordered align-middle table-hover">
          <thead>
            <tr>
              <StyleTh onClick={null}>
                <Form.Check
                  type="checkbox"
                  id="select-all"
                  checked={getCurrentPageData().every((item) =>
                    selectedItems.includes(item)
                  )}
                  onChange={handleSelectAll}
                />
              </StyleTh>
              <StyleTh>회원종류</StyleTh>
              <StyleTh>등록일자</StyleTh>
              {mode === "discharge" && <StyleTh>퇴소일자</StyleTh>}
              <StyleTh>이름/아이디</StyleTh>
              <StyleTh>출석</StyleTh>
              <StyleTh>학교</StyleTh>
              <StyleTh>전화번호</StyleTh>
              <StyleTh>선생님 이름/아이디</StyleTh>
              <StyleTh>정보조회</StyleTh>
            </tr>
          </thead>
          <tbody>
            {data.length > 0 ? (
              data.map((item, idx) => {
                return (
                  <tr key={idx}>
                    <StyleTd onClick={null}>
                      <Form.Check
                        type="checkbox"
                        id={`select-${idx}`}
                        checked={selectedItems.includes(item.userid)}
                        onChange={() => handleSelectItem(item.userid)}
                      />
                    </StyleTd>
                    <StyleTd>
                      {item["type"] === 3
                        ? "학생"
                        : item["type"] === 2
                        ? "선생님"
                        : "최고관리자"}
                    </StyleTd>
                    <StyleTd>
                      {item["startDate"] && getKorDate(item["startDate"])}
                    </StyleTd>
                    {mode === "discharge" && (
                      <StyleTd>
                        {item["endDate"] && getKorDate(item["endDate"])}
                      </StyleTd>
                    )}
                    <StyleTd>
                      <span style={{ fontWeight: "bold" }}>{item["name"]}</span>
                      <br />
                      {item["userid"]}
                    </StyleTd>
                    <StyleTd>
                      {item.hasScoreToday ? (
                        <Badge bg="success">출석</Badge>
                      ) : (
                        <Badge bg="danger">미출석</Badge>
                      )}
                    </StyleTd>
                    <StyleTd>{item["schoolName"]}</StyleTd>
                    <StyleTd>{item["phoneNumber"]}</StyleTd>
                    <StyleTd>
                      {item["type"] === 3 && (
                        <>
                          <span style={{ fontWeight: "bold" }}>
                            {item["teacherName"]}
                          </span>
                          <br />
                          {item["teacher"]}
                        </>
                      )}
                    </StyleTd>
                    <StyleTd>
                      <Button
                        onClick={() =>
                          setSavePage(
                            page,
                            navigate("/user/info/" + item["id"])
                          )
                        }
                        variant="outline-secondary"
                      >
                        회원정보
                      </Button>{" "}
                      <Button
                        onClick={() =>
                          setSavePage(
                            page,
                            navigate("/score/view/" + item["id"])
                          )
                        }
                        variant="outline-info"
                      >
                        성적보기
                      </Button>
                    </StyleTd>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={10}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <span>데이터가 없습니다.</span>
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </Col>
      <Row>
        <Col></Col>
        <Col style={{ display: "flex", justifyContent: "center" }}>
          {data.length > 0 && <Pagination>{pageList}</Pagination>}
        </Col>
        <Col></Col>
      </Row>
    </>
  );
};

export default User;

const StyleTd = styled.td`
  text-align: center;
  white-space: nowrap;
`;

const StyleTh = styled.th`
  text-align: center;
  white-space: nowrap;
`;

const StyleContainer = styled(Container)`
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

const StyleRow = styled(Row)`
  margin-top: 3rem;
`;
