import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/esm/Button";
import { useNavigate } from "react-router-dom";
import { userSession } from "../atom/atoms";
import { useSetRecoilState } from "recoil";
import { DOMAINCONSTRUCTOR } from "../API/DOMAINS";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import { styled } from "styled-components";

const Login = () => {
  const navigate = useNavigate();
  const setUserSession = useSetRecoilState(userSession);
  const login = async (e) => {
    e.preventDefault();

    const formData = {};
    formData.userid = e.target.userid.value;
    formData.passwd = e.target.passwd.value;
    if (!formData.userid) {
      alert("아이디를 입력해주세요.");
      return;
    }
    if (!formData.passwd) {
      alert("비밀번호를 입력해주세요.");
      return;
    }
    await fetch(DOMAINCONSTRUCTOR("login"), {
      method: "POST",
      credentials: "include",
      body: JSON.stringify(formData),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (res.status === 201) {
          return res.json().then((res) => {
            setUserSession(res.session);
            navigate("/");
          });
        } else if (res.status === 400) {
          throw new Error("아이디 또는 비밀번호가 입력되지 않았습니다.");
        } else if (res.status === 401) {
          throw new Error("아이디 또는 비밀번호가 잘못되었습니다.");
        } else if (res.status === 500) {
          return res
            .json()
            .then((res) => {
              throw new Error(
                "서버에 오류가 발생했습니다.\n" + res.error_message
              );
            })
            .catch((error) => {
              throw new Error("서버에 오류가 발생했습니다.\n" + error.message);
            });
        }
      })
      .catch((err) => {
        alert(err.message);
      });
  };

  return (
    <Container>
      <div className="mt-5">
        <h2>로그인</h2>
      </div>
      <StyleRow>
        <Col xs={12}>
          <Form onSubmit={(e) => login(e)}>
            <Form.Group>
              <div className="mb-3">
                <Form.Label htmlFor="userid">아이디</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="아이디"
                  name="userid"
                  id="userid"
                />
              </div>
              <div className="mb-3">
                <Form.Label htmlFor="passwd">비밀번호</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="비밀번호"
                  name="passwd"
                  id="passwd"
                />
              </div>
            </Form.Group>
            <Button
              type="submit"
              className="btn btn-primary"
              style={{ float: "right" }}
            >
              로그인
            </Button>
          </Form>
        </Col>
      </StyleRow>
    </Container>
  );
};

export default Login;

const StyleRow = styled(Row)`
  margin-top: 5rem;
`;
