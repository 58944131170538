import { useRecoilValue } from "recoil";
import { prizeUserAtom } from "../atom/atoms";
import { styled } from "styled-components";
import Button from "react-bootstrap/esm/Button";
import { useRef, useState } from "react";

import { useLocation, useNavigate } from "react-router-dom";
import { Form } from "react-bootstrap";

const GamePrize = () => {
  const navigate = useNavigate();
  const containerRef = useRef([]);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const date = params.get("date");
  const [topMargin, setTopMargin] = useState("4.2");
  const [bottomMargin, setBottomMargin] = useState("0");
  const print = () => {
    if (!containerRef.current) {
      alert("인쇄할 내용이 없습니다.\n새로고침 후 다시 시도해주세요.");
      return;
    }
    const newWindow = window.open("", "_blank");
    containerRef.current.forEach((item, idx) => {
      item.style.position = "relative";
      if (idx === 0) {
        newWindow.document.body.innerHTML = item.innerHTML;
      } else {
        newWindow.document.body.innerHTML += item.innerHTML;
      }
      if (idx === containerRef.current.length - 1) {
        newWindow.focus();
        newWindow.print();
        setTimeout(() => {
          newWindow.close();
        }, 100);
        navigate(-1);
      }
    });
  };

  const addToRef = (el) => {
    if (el && !containerRef.current.includes(el)) {
      containerRef.current.push(el);
    }
  };
  const prizeUser = useRecoilValue(prizeUserAtom);
  const onChangeTopMargin = (e) => {
    if (e.target.value === "") {
      setTopMargin("0");
      return;
    }
    const regex = /^[0-9]+(\.[0-9]*)?$/;

    if (regex.test(e.target.value)) {
      if (e.target.value.startsWith("0")) {
        if (e.target.value.split(".")[0].length === 1) {
          setTopMargin(e.target.value);
          return;
        }
        setTopMargin(e.target.value.slice(1));
        return;
      }
      if (e.target.value.endsWith(".")) {
        setTopMargin(e.target.value);
        return;
      }
      if (e.target.value > 10) {
        alert("상단 여백은 10cm를 넘을 수 없습니다.");
        return;
      }
      setTopMargin(e.target.value);
    }
  };
  const onChangeBottomMargin = (e) => {
    if (e.target.value === "") {
      setBottomMargin("0");
      return;
    }
    const regex = /^[0-9]+(\.[0-9]*)?$/;

    if (regex.test(e.target.value)) {
      if (e.target.value.startsWith("0")) {
        if (e.target.value.split(".")[0].length === 1) {
          setBottomMargin(e.target.value);
          return;
        }
        setBottomMargin(e.target.value.slice(1));
        return;
      }
      if (e.target.value.endsWith(".")) {
        setBottomMargin(e.target.value);
        return;
      }
      setBottomMargin(e.target.value);
    }
  };
  return (
    <StylePrizePreviewContainer>
      <StylePrizePreviewTitleContainer>
        미리보기
      </StylePrizePreviewTitleContainer>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <div
          className="mb-3"
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Form.Label style={{ marginRight: "10px", marginBottom: 0 }}>
            상단 여백
          </Form.Label>
          <Form.Control
            style={{ width: "100px" }}
            placeholder="상단 여백"
            value={topMargin}
            onChange={onChangeTopMargin}
          />
          <Form.Label style={{ marginLeft: "3px", marginBottom: 0 }}>
            cm
          </Form.Label>
        </div>
        {/* <div
          className="mb-3"
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Form.Label style={{ marginRight: "10px", marginBottom: 0 }}>
            하단 여백
          </Form.Label>
          <Form.Control
            style={{ width: "100px" }}
            placeholder="하단 여백"
            value={bottomMargin}
            onChange={onChangeBottomMargin}
          />
          <Form.Label style={{ marginLeft: "3px", marginBottom: 0 }}>
            cm
          </Form.Label>
        </div> */}
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Button onClick={() => print()}>인쇄하기</Button>
      </div>
      {prizeUser &&
        prizeUser.map((item, idx) => {
          return (
            <div
              ref={addToRef}
              style={{
                display: "flex",
                justifyContent: "center",
                background: "gray",
              }}
            >
              <div
                key={idx}
                style={{
                  background: "white",
                  fontFamily: "hy, serif",
                  width: "210mm",
                  minWidth: "210mm",
                  maxWidth: "210mm",
                  height: "297mm",
                  minHeight: "297mm",
                  maxHeight: "297mm",
                  padding: `${topMargin}cm 3.8cm ${bottomMargin}cm 3.8cm`,
                  margin: "0",
                  boxSizing: "border-box",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    marginBottom: "1.5cm",
                    boxSizing: "border-box",
                  }}
                >
                  <div
                    style={{
                      fontSize: "15px",
                      flexBasis: 0,
                      flexGrow: 1,
                      textAlign: "left",
                      boxSizing: "border-box",
                    }}
                  >
                    N{date.split("-")[0]}-{String(item.seq).padStart(3, "0")}
                  </div>
                </div>
                <div
                  style={{
                    boxSizing: "border-box",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    marginTop: "5cm",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      width: "45%",
                      boxSizing: "border-box",
                      flexDirection: "column",
                    }}
                  >
                    <div
                      style={{
                        fontSize: "30px",
                        textShadow:
                          "-0.5px 0px black, 0px 0.5px black, 0.5px 0px black, 0px -0.5px black",
                        boxSizing: "border-box",
                        textAlign: "center",
                      }}
                    >
                      {item.grade}
                    </div>
                    <div
                      style={{
                        marginTop: "0.5cm",
                        fontSize: "30px",
                        textShadow:
                          "-0.5px 0px black, 0px 0.5px black, 0.5px 0px black, 0px -0.5px black",
                        boxSizing: "border-box",
                        textAlign: "center",
                      }}
                    >
                      {item.prize}
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "45%",
                      boxSizing: "border-box",
                      flexDirection: "column",
                    }}
                  >
                    <div
                      style={{
                        position: "absolute",
                        fontSize: "24.5px",
                        boxSizing: "border-box",
                        textShadow:
                          "-0.5px 0px black, 0px 0.5px black, 0.5px 0px black, 0px -0.5px black",
                      }}
                    >
                      소속 {item.school}
                    </div>
                    <div
                      style={{
                        marginTop: "1.5cm",
                        fontSize: "24.5px",
                        boxSizing: "border-box",
                        textShadow:
                          "-0.5px 0px black, 0px 0.5px black, 0.5px 0px black, 0px -0.5px black",
                      }}
                    >
                      성명 {item.name}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
    </StylePrizePreviewContainer>
  );
};

const StylePrizePreviewContainer = styled.div`
  width: 100%;
`;
const StylePrizePreviewTitleContainer = styled.div`
  font-size: 30px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 1cm;
`;

export default GamePrize;
