import { atom } from "recoil";
import { recoilPersist } from "recoil-persist";
const { persistAtom } = recoilPersist({
  key: "sessionStorage",
  storage: sessionStorage,
});
export const userSession = atom({
  key: "userSession",
  default: {},
  effects_UNSTABLE: [persistAtom],
});
export const debouncingValueAtom = atom({
  key: "debouncingValue",
  default: "",
  effects_UNSTABLE: [persistAtom],
});
export const searchValueAtom = atom({
  key: "searchValue",
  default: "",
  effects_UNSTABLE: [persistAtom],
});
export const userPageAtom = atom({
  key: "userPage",
  default: 1,
  effects_UNSTABLE: [persistAtom],
});
export const examPageAtom = atom({
  key: "examPage",
  default: 1,
  effects_UNSTABLE: [persistAtom],
});
export const userPageLimitAtom = atom({
  key: "userPageLimit",
  default: 15,
  effects_UNSTABLE: [persistAtom],
});
export const scorePageAtom = atom({
  key: "scorePage",
  default: 1,
  effects_UNSTABLE: [persistAtom],
});
export const scorePageLimitAtom = atom({
  key: "scorePageLimit",
  default: 15,
  effects_UNSTABLE: [persistAtom],
});
export const modeAtom = atom({
  key: "mode",
  default: "student",
  effects_UNSTABLE: [persistAtom],
});
export const prizeUserAtom = atom({
  key: "prizeUser",
  default: null,
  effects_UNSTABLE: [persistAtom],
});
export const gamePrizeUserAtom = atom({
  key: "gamePrizeUser",
  default: null,
  effects_UNSTABLE: [persistAtom],
});
export const prizeListAtom = atom({
  key: "prizeList",
  default: ["1위", "2위", "3위", "금상", "은상", "동상"],
  effects_UNSTABLE: [persistAtom],
});
