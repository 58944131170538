export const getKorDate = (dateString = new Date()) => {
  const offset = 1000 * 60 * 60 * 9;
  const koreaNow = new Date(new Date(dateString).getTime() + offset);
  return koreaNow.toISOString().split("T")[0];
};
export const getKorFullDate = (dateString = new Date()) => {
  const offset = 1000 * 60 * 60 * 9;
  const koreaNow = new Date(new Date(dateString).getTime() + offset);
  return koreaNow.toISOString().replace("T", " ").split(".")[0];
};
const dotw = ["일", "월", "화", "수", "목", "금", "토"];
export const getKorWithDotw = (dateString = new Date()) => {
  const offset = 1000 * 60 * 60 * 9;
  const koreaNow = new Date(new Date(dateString).getTime() + offset);
  const year = koreaNow.getFullYear();
  const month = koreaNow.getMonth() + 1;
  const date = koreaNow.getDate();
  return `${year}년 ${month}월 ${date}일(${dotw[koreaNow.getDay()]})`;
};

export const getKorWithDotwFullDate = (dateString = new Date()) => {
  const offset = 1000 * 60 * 60 * 9;
  const koreaNow = new Date(new Date(dateString).getTime() + offset);
  const year = koreaNow.getFullYear();
  const month = koreaNow.getMonth() + 1;
  const date = koreaNow.getDate();
  const hour = koreaNow.getHours();
  const minute = koreaNow.getMinutes();
  const second = koreaNow.getSeconds();
  return `${year}년 ${month}월 ${date}일(${
    dotw[koreaNow.getDay()]
  }) ${hour}시 ${minute}분 ${second}초`;
};

export const getDotwFullDate = (dateString = new Date()) => {
  const koreaNow = new Date(new Date(dateString).getTime());
  const year = koreaNow.getFullYear();
  const month = koreaNow.getMonth() + 1;
  const date = koreaNow.getDate();
  const hour =
    koreaNow.getHours() < 10 ? `0${koreaNow.getHours()}` : koreaNow.getHours();
  const minute =
    koreaNow.getMinutes() < 10
      ? `0${koreaNow.getMinutes()}`
      : koreaNow.getMinutes();
  const second =
    koreaNow.getSeconds() < 10
      ? `0${koreaNow.getSeconds()}`
      : koreaNow.getSeconds();
  return `${year}년 ${month}월 ${date}일(${
    dotw[koreaNow.getDay()]
  }) ${hour}시 ${minute}분 ${second}초`;
};
