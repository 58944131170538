import { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { styled } from "styled-components";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import { getKorDate } from "../date/getKorDate";
import { useRecoilState, useSetRecoilState } from "recoil";
import { prizeListAtom, prizeUserAtom } from "../atom/atoms";
import { ButtonGroup, InputGroup } from "react-bootstrap";
import { DOMAINCONSTRUCTOR } from "../API/DOMAINS";
import useAuth from "../hooks/useAuth";

const ResultView = () => {
  const navigate = useNavigate();
  const [gradeFilter, setGradeFilter] = useState("");
  const [data, setData] = useState(null);
  const [gradeArr, setGradeArr] = useState([]);
  const [filteredData, setFilteredData] = useState(null);
  const [filteredDataLength, setFilteredDataLength] = useState(0);
  const [searchTeacher, setSearchTeacher] = useState("");
  const [selectedItems, setSelectedItems] = useState([]);
  const setPrizeUser = useSetRecoilState(prizeUserAtom);
  const session = useAuth();
  const handleSelectAll = () => {
    if (selectedItems.length !== filteredData.length) {
      setSelectedItems(filteredData.map((item) => item.id));
    } else {
      setSelectedItems([]);
    }
  };

  const handleSelectItem = (id) => {
    if (selectedItems.includes(id)) {
      const updatedSelectedItems = selectedItems.filter((item) => {
        return item !== id;
      });
      setSelectedItems(updatedSelectedItems);
      return;
    }

    const updatedSelectedItems = [...selectedItems, id];
    setSelectedItems(updatedSelectedItems);
  };

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const id = params.get("id");
  const type = params.get("type");
  const date = params.get("date");
  const getData = async () => {
    await fetch(`${DOMAINCONSTRUCTOR("game")}/${id}`)
      .then(async (res) => {
        if (res.status === 200) {
          const res_1 = await res.json();
          setData(res_1.data);
        } else {
          const res_2 = await res.json();
          alert(
            "정보를 불러오는데 실패하였습니다.\n새로고침 후 다시 시도해주세요.\n",
            res_2.error_message
          );
        }
      })
      .catch((error) => {
        alert(
          "정보를 불러오는데 실패하였습니다.\n새로고침 후 다시 시도해주세요.\n",
          error
        );
      });
  };
  useEffect(() => {
    getData();
  }, []);
  const getPrint = () => {
    if (selectedItems.length === 0) return;
    setPrizeUser(
      filteredData.filter((item) => selectedItems.includes(item.id))
    );
    if (type === "game") navigate(`/game/gamePrize?date=${date}`);
    else navigate(`/game/examPrize?date=${date}`);
  };

  useEffect(() => {
    if (!data) return;
    const filteredData = data.filter((item) => {
      if (gradeFilter === "") return true;
      return item["grade"] === gradeFilter;
    });
    const filteredDataWithSearchTeacher = filteredData.filter((item) => {
      if (searchTeacher === "") return true;
      return item["teacher"].includes(searchTeacher);
    });
    setFilteredData(filteredDataWithSearchTeacher);
    setFilteredDataLength(filteredDataWithSearchTeacher.length);
  }, [data, gradeFilter, searchTeacher]);

  useEffect(() => {
    if (!data) return;
    const uniqueGrade = data
      .filter((item, index, array) => {
        return array.findIndex((v) => v["grade"] === item["grade"]) === index;
      })
      .map((i) => {
        return i["grade"];
      })
      .sort();
    setGradeArr(uniqueGrade);
  }, [data]);
  const [prizeList, setPrizeList] = useRecoilState(prizeListAtom);
  const [modal, setModal] = useState(false);
  const addPrizeList = () => {
    setPrizeList([...prizeList, "상 이름"]);
  };
  const deletePrizeList = (idx) => {
    setPrizeList(prizeList.filter((_, index) => index !== idx));
  };
  const upPrizeList = (idx) => {
    if (idx === 0) return;
    let tempPrizeList = [...prizeList];
    const temp = tempPrizeList[idx - 1];
    tempPrizeList[idx - 1] = tempPrizeList[idx];
    tempPrizeList[idx] = temp;

    setPrizeList([...tempPrizeList]);
  };
  const downPrizeList = (idx) => {
    if (idx === prizeList.length - 1) return;
    let tempPrizeList = [...prizeList];
    const temp = tempPrizeList[idx + 1];
    tempPrizeList[idx + 1] = tempPrizeList[idx];
    tempPrizeList[idx] = temp;

    setPrizeList([...tempPrizeList]);
  };
  const [prizeEditMode, setPrizeEditMode] = useState(
    Array(prizeList.length).fill(false)
  );
  useEffect(() => {
    setPrizeEditMode(Array(prizeList.length).fill(false));
  }, [prizeList.length]);
  const handlePrizeEditMode = (idx) => {
    let tempPrizeEditMode = [...prizeEditMode];
    tempPrizeEditMode[idx] = true;
    setPrizeEditMode([...tempPrizeEditMode]);
  };

  const savePrizeList = (e, idx) => {
    e.preventDefault();
    const formId = `form${idx}`;
    let tempPrizeList = [...prizeList];
    tempPrizeList[idx] = e.target[formId].value;
    setPrizeList([...tempPrizeList]);

    let tempPrizeEditMode = [...prizeEditMode];
    tempPrizeEditMode[idx] = false;
    setPrizeEditMode([...tempPrizeEditMode]);
  };
  useEffect(() => {
    if (!bodyRef.current) return;
    if (modal) bodyRef.current.style.overflow = "hidden";
    else bodyRef.current.style.overflow = "auto";
  }, [modal]);
  const bodyRef = useRef();
  const applyPrize = async (e) => {
    e.preventDefault();
    if (!window.confirm("적용하시겠습니까?")) return;
    const prizeNum = {};
    prizeList.forEach((item, idx) => {
      const formId = `formPrizeNum${idx}`;
      prizeNum[item] = e.target[formId].value;
    });
    const formData = {};
    formData["prizeNum"] = prizeNum;
    formData["filteredData"] = filteredData.map((item) => {
      return item["id"];
    });

    await fetch(`${DOMAINCONSTRUCTOR("game")}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    }).then((res) => {
      if (res.status === 200) {
        alert("적용되었습니다.");
        getData();
      } else {
        return res.json().then((res) => {
          alert("오류가 발생하였습니다.\n", res.error_message);
        });
      }
    });
  };

  const [scoreEditMode, setScoreEditMode] = useState(false);
  const hanldeScoreEditMode = (mode) => {
    setScoreEditMode(mode);
  };

  const saveScore = async (e) => {
    e.preventDefault();
    const changeData = {
      // user : [jusan, amsan]
    };
    filteredData.forEach((item, idx) => {
      const formScoreSum = `formScoreSum${item["id"]}`;
      const sum = e.target[formScoreSum].value;
      if (item["sum"] !== Number(sum)) {
        changeData[item["id"]] = sum;
      }
    });
    if (Object.keys(changeData).length === 0) {
      alert("변경된 점수가 없습니다.");
      return;
    }
    if (!window.confirm("저장하시겠습니까?")) return;
    const formData = {};
    formData["data"] = changeData;
    await fetch(`${DOMAINCONSTRUCTOR("game_score")}/${id}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    }).then((res) => {
      if (res.status === 200) {
        alert("저장되었습니다.");
        setScoreEditMode(false);
        getData();
      } else {
        return res.json().then((res) => {
          alert("오류가 발생하였습니다.\n", res.error_message);
        });
      }
    });
  };

  if (session.type !== 1) navigate("/");
  if (!filteredData) return null;
  return (
    <>
      {modal && (
        <>
          <StyleBackDrop />
          <StyleModal>
            <StyleModalTitleContainer>
              <span>상 종류 설정</span>
              <Button
                onClick={() => setModal(false)}
                variant="outline-danger"
                style={{
                  position: "absolute",
                  right: "20px",
                }}
              >
                X
              </Button>
            </StyleModalTitleContainer>
            <StyleScrollBodyContainer>
              {prizeList &&
                prizeList.map((item, idx) => {
                  return (
                    <StyleModalListContainer>
                      {prizeEditMode[idx] ? (
                        <Form
                          id="setPrizeList"
                          onSubmit={(e) => savePrizeList(e, idx)}
                        >
                          <InputGroup>
                            <Form.Control
                              type="text"
                              defaultValue={item}
                              id={`form${idx}`}
                            />
                            <Button
                              type="submit"
                              variant="success"
                              form="setPrizeList"
                            >
                              저장
                            </Button>
                          </InputGroup>
                        </Form>
                      ) : (
                        <span>{item}</span>
                      )}
                      <ButtonGroup>
                        {!prizeEditMode[idx] && (
                          <Button
                            variant="outline-secondary"
                            onClick={() => handlePrizeEditMode(idx)}
                          >
                            수정
                          </Button>
                        )}
                        <Button
                          variant="outline-secondary"
                          onClick={() => upPrizeList(idx)}
                        >
                          ▲
                        </Button>
                        <Button
                          variant="outline-secondary"
                          onClick={() => downPrizeList(idx)}
                        >
                          ▼
                        </Button>
                        <Button
                          variant="outline-danger"
                          onClick={() => deletePrizeList(idx)}
                        >
                          X
                        </Button>
                      </ButtonGroup>
                    </StyleModalListContainer>
                  );
                })}
              <div className="d-grid gap-2" style={{ width: "90%" }}>
                <Button
                  variant="outline-danger"
                  size="lg"
                  onClick={() => addPrizeList()}
                >
                  +
                </Button>
              </div>
            </StyleScrollBodyContainer>
          </StyleModal>
        </>
      )}
      <StyleResultContainer ref={bodyRef}>
        <StyleContainer
          style={{ marginTop: "1rem", justifyContent: "space-between" }}
        >
          <StyleButton
            variant="primary"
            disabled={selectedItems.length === 0}
            onClick={() => getPrint()}
          >
            {selectedItems.length > 0 && `${selectedItems.length}명 `} 인쇄하기
          </StyleButton>
          {type === "game" &&
            (!scoreEditMode ? (
              <StyleButton
                variant="primary"
                onClick={() => hanldeScoreEditMode(true)}
              >
                수정모드
              </StyleButton>
            ) : (
              <div>
                <StyleButton
                  variant="danger"
                  onClick={() => hanldeScoreEditMode(false)}
                >
                  취소하기
                </StyleButton>
                <StyleButton variant="success" type="submit" form="scoreForm">
                  저장하기
                </StyleButton>
              </div>
            ))}
        </StyleContainer>

        <StyleContainer style={{ justifyContent: "space-between" }}>
          <div>
            <StyleButton
              style={{
                backgroundColor: gradeFilter === "" ? "#B1D2AB" : "#FFFFFF",
                color: gradeFilter === "" ? "#FFFFFF" : "#191919",
                borderColor: "#B1D2AB",
              }}
              disabled={scoreEditMode}
              onClick={() => setGradeFilter("")}
            >
              전체
            </StyleButton>
            {gradeArr.map((item, _) => {
              return (
                <StyleButton
                  style={{
                    backgroundColor:
                      gradeFilter === item ? "#76BEDB" : "#FFFFFF",
                    color: gradeFilter === item ? "#FFFFFF" : "#191919",
                    borderColor: "#76BEDB",
                  }}
                  disabled={scoreEditMode}
                  onClick={() => setGradeFilter(item)}
                >
                  {item}
                </StyleButton>
              );
            })}
          </div>
          {type === "game" && gradeFilter !== "" && !scoreEditMode && (
            <StyleButton
              style={{
                backgroundColor: "#5AAED1",
                color: "#FFFFFF",
                borderColor: "#8BAEBF",
              }}
              onClick={() => setModal(true)}
            >
              상 종류 설정
            </StyleButton>
          )}
        </StyleContainer>

        {type === "game" && gradeFilter !== "" && !scoreEditMode && (
          <>
            <Form id="prizeList" onSubmit={applyPrize}>
              <Container
                style={{
                  backgroundColor: "#f5f5f5",
                }}
              >
                <Row>
                  {prizeList.map((item, idx) => {
                    return (
                      <StyleCol>
                        <StyleLable style={{ fontSize: "14px" }}>
                          {item} 인원수
                        </StyleLable>
                        <Form.Control
                          type="text"
                          name={item}
                          id={`formPrizeNum${idx}`}
                        />
                      </StyleCol>
                    );
                  })}
                </Row>
              </Container>
              <Container
                style={{
                  padding: "10px",
                  borderRadius: "3px",
                  backgroundColor: "#f5f5f5",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Button type="submit" form="prizeList">
                  적용하기
                </Button>
              </Container>
            </Form>
          </>
        )}
        <Container
          style={{
            padding: "10px",
            borderRadius: "3px",
            backgroundColor: "#f5f5f5",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Form.Control
            type="text"
            placeholder="교사이름"
            style={{ width: "200px" }}
            onChange={(e) => setSearchTeacher(e.target.value)}
          />
        </Container>
        <Container style={{ padding: 0 }}>
          <Form id="scoreForm" onSubmit={saveScore}>
            <div className="table-responsive">
              <table className="table table-bordered align-middle table-striped">
                <thead>
                  <tr className="thead-dark">
                    <StyleTh onClick={null}>
                      <Form.Check
                        type="checkbox"
                        id="select-all"
                        checked={filteredData
                          .map((item) => {
                            return item["id"];
                          })
                          .every((item) => selectedItems.includes(item))}
                        onChange={handleSelectAll}
                        tabIndex={-1}
                      />
                    </StyleTh>
                    <StyleTh>{`${filteredDataLength}명`}</StyleTh>
                    <StyleTh>학년</StyleTh>
                    <StyleTh>이름</StyleTh>
                    {type === "game" && <StyleTh>상장번호</StyleTh>}
                    <StyleTh>수험번호</StyleTh>
                    {type === "exam" && <StyleTh>응시급수</StyleTh>}
                    {type === "game" && (
                      <>
                        <StyleTh>합계</StyleTh>
                        <StyleTh>순위(점수)</StyleTh>
                        <StyleTh>상장</StyleTh>
                      </>
                    )}
                    {type === "exam" && <StyleTh>자격증번호</StyleTh>}
                    <StyleTh>학교</StyleTh>
                    <StyleTh>생년월일</StyleTh>
                    <StyleTh>지도교사</StyleTh>
                  </tr>
                </thead>
                <tbody>
                  {filteredData ? (
                    filteredData
                      .sort((a, b) => {
                        // sort by sumRank
                        if (a["sumRank"] < b["sumRank"]) {
                          return -1;
                        }
                        if (a["sumRank"] > b["sumRank"]) {
                          return 1;
                        }
                        return 0;
                      })
                      .map((item, idx) => {
                        return (
                          <tr key={idx}>
                            <StyleTd onClick={null}>
                              <Form.Check
                                type="checkbox"
                                id={`select-${idx}`}
                                checked={selectedItems.includes(item["id"])}
                                onChange={() => handleSelectItem(item["id"])}
                                tabIndex={-1}
                              />
                            </StyleTd>
                            <StyleTd>{idx + 1}</StyleTd>
                            <StyleTd>{item["grade"]}</StyleTd>
                            <StyleTd>{item["name"]}</StyleTd>
                            {type === "game" && (
                              <StyleTd>
                                N{date.split("-")[0]}-
                                {String(item.seq).padStart(3, "0")}
                              </StyleTd>
                            )}
                            <StyleTd>{item["testNum"]}</StyleTd>
                            {type === "exam" && (
                              <StyleTd>{item["examGrade"]}</StyleTd>
                            )}
                            {type === "game" && (
                              <>
                                {scoreEditMode ? (
                                  <StyleTd>
                                    <Form.Control
                                      id={`formScoreSum${item["id"]}`}
                                      style={{
                                        minWidth: "100px",
                                        maxWidth: "100px",
                                        margin: "0 auto",
                                      }}
                                      type="text"
                                      defaultValue={item["sum"]}
                                    />
                                  </StyleTd>
                                ) : (
                                  <StyleTd>{item["sum"]}</StyleTd>
                                )}
                                <StyleTd>{item["sumRank"]}</StyleTd>
                                <StyleTd>
                                  {item["prize"] ? item["prize"] : "-"}
                                </StyleTd>
                              </>
                            )}
                            {type === "exam" && (
                              <StyleTd>{item["license"]}</StyleTd>
                            )}
                            <StyleTd>{item["school"]}</StyleTd>
                            <StyleTd>
                              {item["birthDate"] &&
                                getKorDate(item["birthDate"])}
                            </StyleTd>
                            <StyleTd>{item["teacher"]}</StyleTd>
                          </tr>
                        );
                      })
                  ) : (
                    <tr>
                      <StyleTd colSpan="12" style={{ textAlign: "center" }}>
                        등록된 데이터가 없습니다.
                      </StyleTd>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </Form>
        </Container>
      </StyleResultContainer>
    </>
  );
};

export default ResultView;

const StyleModal = styled.div`
  z-index: 101;
  position: absolute;
  background: white;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
  height: 600px;
  border-radius: 10px;
  display: flex;

  align-items: center;
  flex-direction: column;
`;
const StyleModalTitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 25px;
  font-weight: bold;
  width: 100%;
  height: 70px;
  border-bottom: 1px solid black;
  margin-bottom: 10px;
`;
const StyleScrollBodyContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;

  overflow-y: scroll;
`;
const StyleModalListContainer = styled.div`
  display: flex;
  width: 90%;
  min-height: 50px;
  margin-bottom: 5px;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
  justify-content: space-between;

  ${(props) =>
    props.hover &&
    `
    &:hover {
      background-color: #F1948A;
      color: white;
    }`}
`;

const StyleBackDrop = styled.div`
  z-index: 100;
  position: absolute;
  background: black;
  opacity: 0.5;
  width: 100%;
  height: 100%;
`;
const StyleResultContainer = styled.div`
  z-index: 99;
  width: 100%;
  height: 100%;
  background: white;
`;

const StyleContainer = styled(Container)`
  display: flex;
  flex-direction: row;
  background-color: #f5f5f5;
  border-radius: 3px;
`;

const StyleButton = styled(Button)`
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  margin-left: 0.2rem;
  margin-right: 0.2rem;
`;

const StyleTh = styled.th`
  font-size: 14px;
  text-align: center;
  white-space: nowrap;
`;

const StyleTd = styled.td`
  font-size: 14px;
  text-align: center;
  white-space: nowrap;
`;

const StyleCol = styled(Col)`
  margin-top: 10px;
  margin-bottom: 10px;
`;

const StyleLable = styled(Form.Label)`
  font-size: 14px;
  font-weight: bold;
`;
