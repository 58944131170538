import { useNavigate } from "react-router-dom";
import { styled } from "styled-components";
import { Container, Row, Col, Form } from "react-bootstrap";

const Score = () => {
  const test = [
    {
      todayPlay: true,
      이름: "김학생",
      아이디: "kstudent",
      회원종류: "학생",
      학교: "한국초",
      등록일자: "2023-06-28",
      선생님이름: "김선생",
      선생님아이디: "kteacher",
    },
    {
      todayPlay: false,
      이름: "이학생",
      아이디: "lstudent",
      회원종류: "학생",
      학교: "서울초",
      등록일자: "2023-06-28",
      선생님이름: "이선생",
      선생님아이디: "lteacher",
    },
  ];
  const navigate = useNavigate();
  return (
    <Container>
      <StyleContainer>
        <Row>
          <h2>성적보기</h2>
        </Row>
        <Row>
          <Col></Col>
          <Col></Col>
          <Col>
            <Form.Control type="text" placeholder="검색" />
          </Col>
        </Row>
      </StyleContainer>
      <StyleContainer>
        <div className="table-responsive">
          <table className="table table-bordered align-middle table-hover">
            <thead>
              <tr className="thead-dark">
                <StyleTh>출석</StyleTh>
                <StyleTh>이름/아이디</StyleTh>
                <StyleTh>회원종류</StyleTh>
                <StyleTh>학교</StyleTh>
                <StyleTh>등록일자</StyleTh>
                <StyleTh>선생님 이름/아이디</StyleTh>
              </tr>
            </thead>
            <tbody>
              {test.map((item, idx) => {
                return (
                  <tr
                    key={idx}
                    style={{ cursor: "pointer" }}
                    onClick={() => navigate(`/score/view/${item["아이디"]}`)}
                  >
                    <StyleTd
                      style={{
                        backgroundColor: item["todayPlay"]
                          ? "#ACFA58"
                          : "#F6CECE",
                      }}
                    >
                      {item.todayPlay ? "✓" : "x"}
                    </StyleTd>
                    <StyleTd>
                      <span style={{ fontWeight: "bold" }}>{item["이름"]}</span>
                      <br />
                      {item["아이디"]}
                    </StyleTd>
                    <StyleTd>{item["회원종류"]}</StyleTd>
                    <StyleTd>{item["학교"]}</StyleTd>
                    <StyleTd>{item["등록일자"]}</StyleTd>
                    <StyleTd>
                      <span style={{ fontWeight: "bold" }}>
                        {item["선생님이름"]}
                      </span>
                      <br />
                      {item["선생님아이디"]}
                    </StyleTd>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </StyleContainer>
    </Container>
  );
};

export default Score;

const StyleContainer = styled(Container)`
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

const StyleTh = styled.th`
  text-align: center;
  white-space: nowrap;
`;

const StyleTd = styled.td`
  text-align: center;
  white-space: nowrap;
`;
