import { styled } from "styled-components";

const Home = () => {
  return (
    <StyledMainContainer>
      <StyledH1>
        <StyledSpan>주</StyledSpan>
        <StyledSpan>산</StyledSpan>
        <StyledSpan>암</StyledSpan>
        <StyledSpan>산</StyledSpan>
        <StyledSpan>F</StyledSpan>
        <StyledSpan>L</StyledSpan>
        <StyledSpan>A</StyledSpan>
        <StyledSpan>S</StyledSpan>
        <StyledSpan>H</StyledSpan>
      </StyledH1>
    </StyledMainContainer>
  );
};
const StyledMainContainer = styled.div`
  width: 100%;
  height: 100%;
  background-image: linear-gradient(to top, #a3bded 0%, #6991c7 100%);
  -webkit-font-smoothing: antialiased;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledH1 = styled.h1`
  height: 100px;
  user-select: none;
  -webkit-user-select: none;
`;

const StyledSpan = styled.span`
  position: relative;
  top: 20px;
  display: inline-block;
  font-size: 3rem;
  color: rgb(254, 254, 254);
  text-shadow: 0 1px 0 #ccc, 0 2px 0 #ccc, 0 3px 0 #ccc, 0 4px 0 #ccc,
    0 5px 0 #ccc, 0 6px 0 transparent, 0 7px 0 transparent, 0 8px 0 transparent,
    0 9px 0 transparent, 0 10px 10px rgba(0, 0, 0, 0.4);
`;
export default Home;
