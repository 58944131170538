import { styled } from "styled-components";
import NavBar from "./NavBar";
import { Outlet } from "react-router-dom";

function App() {
  return (
    <StyleAppContainer>
      <NavBar />
      <Outlet />
    </StyleAppContainer>
  );
}
const StyleAppContainer = styled.div`
  width: 100%;
  height: 100%;
`;

export default App;
